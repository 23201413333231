import React, { useEffect, useState } from 'react';
import image from '../../assets/imageprofile.png';
import { TbCurrencyNaira } from "react-icons/tb";
import { MdOutlineAttachMoney } from "react-icons/md";
import { HiArrowsUpDown } from "react-icons/hi2";
import { useUser } from '../../userContext/UserContext';
import AccountDetails from '../../Components/Wallets/CreatePin/AccountDetails';
import PinDropdown from '../../Reuseables/Dropdown/PinDropdown';
import axios from 'axios';

export function RightPane() {
    const { user } = useUser();

    const [mainBalance, setMainBalance] = useState(localStorage.getItem('mainAccountBalance') || '');
    const [escrowBalance, setEscrowBalance] = useState(localStorage.getItem('escrowAccountBalance') || '');

    const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;


    useEffect(() => {
        
    }, [mainBalance, escrowBalance, pinUrl]);

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="hidden md:flex flex-col h-full gap-4 py-4 px-4 w-96">
            <div className="flex flex-col w-full py-16 gap-4 rounded-lg">
                <p className="font-lato text-[20px] font-medium">Welcome, {user.username}</p>
                <div className="flex flex-col justify-center items-center gap-2">
                    <img src={image} className="rounded-full border-2 border-orange-500 p-1" alt="Profile" />
                    <p className="font-lato text-[16px] font-medium">{user.username}</p>

                    <button className="text-blue-950 font-medium text-[12px] px-5 py-2 bg-blue-200 rounded-2xl">
                        Edit Profile
                    </button>
                </div>

                <div>
                    <p className="text-gray-500 text-[10px] font-lato">Joined</p>
                    <p className="text-[10px] font-lato">June 22, 2020</p>
                </div>
            </div>
            <div>
                <AccountDetails />
            </div>
            <div className='w-full lg:md:block'>
                <PinDropdown />
            </div>
            <div className="flex flex-col p-4 justify-between h-[380px]">
                <div className="flex justify-start flex-col">
                    <p className="text-black font-medium">Main Wallet</p>
                    <div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Total Balance:</p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><TbCurrencyNaira /> <span>{mainBalance}</span></p>
                        </div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Dollar:</p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><MdOutlineAttachMoney /> <span>132.00</span></p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start flex-col">
                    <p className="text-black font-medium">Escrow Wallet</p>
                    <div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Total Balance:</p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><TbCurrencyNaira /> <span>{escrowBalance}</span></p>
                        </div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Dollar:</p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><MdOutlineAttachMoney /> <span>132.00</span></p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center">
                    <button className="bg-blue-900 text-white py-3 px-16 flex items-center gap-3">
                        <HiArrowsUpDown className="text-white" />
                        Buy Now
                    </button>
                </div>
            </div>
        </div>
    );
}
