import './App.css';
import { useRoutes, Navigate } from 'react-router-dom';
import { ROUTES } from './Route/Route';
import { UserProvider, useUser } from './userContext/UserContext';

const PrivateRoute = ({ element }) => {
  const { user } = useUser();
  const isAuthenticated = !!user;

  return isAuthenticated ? element : <Navigate to="/login" />;
};

function App() {
  const routing = useRoutes(
    ROUTES.map(route => ({
      ...route,
      element: route.private ? (
        <PrivateRoute element={route.element} />
      ) : (
        route.element
      ),
    }))
  );

  return (
    <UserProvider>
      {routing}
    </UserProvider>
  );
}

export default App;
