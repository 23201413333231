import React from 'react';
import { useNavigate } from 'react-router-dom';

const TransferSuccessScreen = ({ status, onPressOk }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/dashboard', { state: { option: 'Dashboard' } });
  };

  let message = '';
  switch (status) {
    case 'success':
      message = 'Withdrawal successful!';
      break;
    case 'failure':
      message = 'Withdrawal failed. Please try again later.';
      break;
    case 'pending':
      message = 'Withdrawal is pending. Please check again later.';
      break;
    default:
      message = '';
      break;
  }

  return (
    <div className={styles.container}>
      <p className={styles.message}>{message}</p>
      <button onClick={handleBack} className={styles.button}>OK</button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  message: {
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'center'
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
  }
};

export default TransferSuccessScreen;
