import React from 'react';

const PinManager = ({ accountNumber, escrowAccountNumber }) => {
  return (
    <div className="bg-white p-6 rounded shadow-md">
      <h2 className="text-xl mb-4">Pin Management Page</h2>
      <p>Account Number: {accountNumber}</p>
      <p>Escrow Account Number: {escrowAccountNumber}</p>
    </div>
  );
};

export default PinManager;
