import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function SidePricing() {
    const [isOpen, setIsOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const navigate = useNavigate();

    const pricing = () => {
        navigate('/pricing');
    };


    const handleMouseEnter = () => {
        clearTimeout(hoverTimeout);
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setIsOpen(false);
        }, 200);
        setHoverTimeout(timeout);
    };

    return (
        <div
            className="container w-full text-left"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="flex m-0 mt-2 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="py-1 w-96" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <p className='text-gray-400 font-bold ml-4' onClick={pricing}>Pricing</p>
                </div>
            </div>
        </div>
    );
}

export default SidePricing