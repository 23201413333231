import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from "./SelectComponent.module.css";


const SelectBank = ({ options, onSelect }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setModalVisible(false);
  };

  return (
    <div className={styles.container}>
      <div onClick={() => setModalVisible(true)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
        <span>{selectedOption.label}</span>
      </div>
      {modalVisible && (
       <div className={styles.modalOverlay} onClick={() => setModalVisible(false)}>
         <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            {options.map((option, index) => (
              <div key={index} onClick={() => handleSelectOption(option)} style={{ padding: 10, borderWidth: 1, borderStyle: 'solid', margin: 10, fontSize: 20, cursor: 'pointer' }}>
                {option.label}
              </div>
            ))}
            <button onClick={() => setModalVisible(false)} style={{ marginTop: 10, padding: 10, cursor: 'pointer' }}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectBank;

