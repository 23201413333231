import React, { useState } from 'react';
import SideBankingPlus from './SideBankingPlus';
import SideBusinesses from './SideBusinesses';
import SidePayment from './SidePayment';
import SidePricing from './SidePricing';
import { MdLogout } from "react-icons/md";
import SideResources from './SideResources';
import SideSupport from './SideSupport';
import { Link } from 'react-router-dom';

function SideMenuIndex() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState('');

  const handleChoice = (choice) => {
      setSelectedChoice(choice);
      setIsPopupOpen(true);
  };

  const closePopup = () => {
      setIsPopupOpen(false);
      setSelectedChoice('');
  };
  return (
    <>
    <SideBankingPlus />
    <SideBusinesses />
    <SidePayment />
    <SidePricing />
    <SideResources />
    <SideSupport />
    </>
  )
}

export default SideMenuIndex