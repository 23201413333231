import React, { useState } from 'react';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';

const CountryFlag = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({ name: "Nigeria", code: "NG" });

  const countries = [
    { name: "Nigeria", code: "NG" },
    // { name: "United States", code: "US" },
    { name: "United Kingdom", code: "GB" },
    // { name: "Canada", code: "CA" },
    // { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH"},
    { name: "Rwanda", code: "RW"},
  ];

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setIsOpen(false); 
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setHoverTimeout(setTimeout(() => setIsOpen(true), 200)); 
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setHoverTimeout(setTimeout(() => setIsOpen(false), 200)); 
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      marginTop: 0,
      borderRadius: 0,
      boxShadow: 'none',
      border: '1px solid #ccc',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: state.isSelected ? '#f0f0f0' : state.isFocused ? '#f7f7f7' : 'white',
      color: 'black',
    }),
  };

  const FlagOption = (props) => (
    <div {...props.innerProps} style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
      <FlagIcon code={props.data.code} size={24} />
      <span style={{ marginLeft: '8px' }}>{props.data.code}</span>
    </div>
  );

  return (
    <div className="relative inline-block cursor-pointer mt-1.5" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="flex items-center justify-center">
        <FlagIcon code={selectedCountry.code} size={24} />
      </div>
      {isOpen && (
        <div className="absolute left-0 mt-2 w-48 cursor-pointer bg-white border rounded-md shadow-lg transition-opacity duration-200">
          <Select
            value={null} 
            onChange={handleCountryChange}
            options={countries}
            components={{ Option: FlagOption }}
            styles={customStyles}
            isSearchable={false}
            placeholder="" 
            menuIsOpen
            controlShouldRenderValue={false} 
          />
        </div>
      )}
    </div>
  );
};

export default CountryFlag;
