import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import gif from "../../../images/mvp2.gif";
import { useNavigate } from "react-router-dom";
import style from "./Intro.module.css"
import IntroSect from "../../../Reuseables/introsection/IntroSect";

const Intro = () => {
    const navigate = useNavigate();

    const home = () => {
        navigate('/home');
    };
    const signup = () => {
        navigate('/signup');
    };

    const slides = [
        { id: 1, text: 'Unlocking barriers for all payments' },
        { id: 2, text: 'Simplifying transactions for Africa' },
        { id: 3, text: 'Building global business trust for Africa' }

    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        console.log("currentSlide:", currentSlide);
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentSlide, slides.length]);


    return (
        // <IntroSect text={"Unlocking barriers"} imgage={gif} img={style.img} text2={"for all payments"} 
        // text3={"Accept payments from anywhere. Pay vendors with a direct or escrow account. Retain control over payouts. Never let trust cost you the deal."} btn={"Create a free account"} btn2={"Contact sales"}/>
        <main className='bg-white mr' >
            <section className="container mt-28 mx-auto h-full w-fit flex flex-row top-24">
                <div className={style.unlock}>
                    {/* <div className={style.barrier}>
                            <span className={style.span}>Win customer trust, </span>
                            fuel your growth
                        </div> */}


                    <div className={style.barrier}>
                        <div className={style.slideContainer}>
                            {slides.map((slide, index) => (
                                <div
                                    key={slide.id}
                                    className={`${style.slide} ${index === currentSlide ? style.active : ''}`}
                                >
                                    <span className={style.span}>{slide.text}</span>
                                </div>
                            ))}
                        </div>
                        {/* <span className={style.span}>Unlocking barriers </span>
                    for all payments */}
                    </div>

                    {/* <div className={style.barrier}>
                    <span className={style.span}>Simplifying transactions </span>
                    for Africa
                </div> */}

                    {/* <div className={style.barrier}>
                    <span className={style.span}>Building trust </span>
                    for Africa
                </div> */}

                    <p className={style.trust}>Sell to everyone. Cashout from anywhere.
                        Retain control over payouts. Never let trust cost you the deal.
                    </p>
                    {/* <p className={style.trust}>Accept fiat and crypto payments from anywhere.
                    Pay vendors with a fiat or crypto account. Retain control over payouts.
                    Never let trust cost you the deal.
                </p> */}

                    <ul>
                        <li className="ml-0 ">
                            <Link to={'/signup'}>
                                <button className="lg:text-xl text-[15px] lg:py-4 py-5 lg:w-60 w-40 text-white lg:rounded-full hover:bg-blue-700 rounded-2xl bg-[#0887ef]">Create a free account</button>
                            </Link>
                            <Link to={'/signup'} >
                                <button className="flex lg:text-xl text-[16px] lg:py-3 py-3 lg:ml-28 lg:w-fit w-40 text-[#0887ef] items-center lg:rounded-full rounded-2xl hover:bg-blue-700 hover:text-white bg-[#d7ecfa]"><h2 className="ml-4">Contact Sales</h2>
                                    <svg className="" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#068bf7" />
                                        <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#068bf7" />
                                    </svg>
                                </button>
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={style.gif}>

                    <img src={gif} className={style.img} alt="Cinque Terre" />

                </div>
            </section>
        </main>
    );

};

export default Intro;
