import React from "react";
import { Link } from "react-router-dom";
import GetStarted from "../Home/GetStarted/GetStarted";
import Individuals from "../Home/Individual/Individuals";
import Delivery from "../Home/Delivery/Delivery";
import Intro from "../Home/Intro/Intro";
import Choose from "../Home/Choose/Choose";
import Services from "../Home/Services/Services";
import Brands from "../Home/Brands/Brands";
import Products from "../Home/Products/Products";

const About = () => {
    return(
    <>
    {/* <GetStarted />
    <Individuals />
    <Delivery />
    <Intro />
    <Choose />
    <Services />
    <Brands />
    <Products /> */}

</>
    )
}

export default About;