import React from "react";
import house from "../images/house-frame-removebg-preview.png";
import IntroSect from "../Reuseables/introsection/IntroSect";
import banner from "../images/banner-payment-links.svg";
import { MdMargin } from "react-icons/md";


const Investrust = () => {

    return (
    <IntroSect text={"Win client trust,"} imgage={house} size={{width: 600, top: 5}} alt={"house-frame"} text2={"fuel your growth"} 
        text3={"Never let trust cost you the BIG deal. Win more with Investrust. Collect direct and escrow payments in your two-in-one business account from anywhere. Pay real estate agents from anywhere and retain control over payouts."} btn={"Sign Up Now"} btn2={"Contact sales"}/>
        
    );

};

export default Investrust;