import React, { useEffect, useState } from 'react';
import logo from "../../assets/Tranxact only.png";
import profile from "../../assets/profileimage.png";
import { TbCurrencyNaira } from "react-icons/tb";
import { GrClose } from "react-icons/gr";
import { SideNav } from "../SideNav/SideNav";
import { CiSquareAlert } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { useUser } from "../../userContext/UserContext";
import AccountDetails from '../../Components/Wallets/CreatePin/AccountDetails';
import axios from 'axios';
import { Balance } from '../../Components/Wallets/Balance/Balance';

export function LeftPane() {
    const [isSideMenuOpen, setSideMenu] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [mainBalance, setMainBalance] = useState(localStorage.getItem('mainBalance') || '');
    const [escrowBalance, setEscrowBalance] = useState(localStorage.getItem('escrowBalance') || '');
    const navigate = useNavigate();
    const { user } = useUser();

    const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const home = () => {
        navigate('/home');
    }

    useEffect(() => {
        // const fetchAccountDetails = async () => {
        //     if (mainBalance && escrowBalance) return;

        //     try {
        //         const token = localStorage.getItem('authToken');
        //         if (!token) {
        //             console.error('No auth token found.');
        //             return;
        //         }

        //         console.log('Fetching account details...');
        //         const response = await axios.get(`${pinUrl}/api/v2/wallet`, {
        //             headers: {
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         });
        //         console.log('Account Details Response:', response.data);

        //         setMainBalance(response.data.mainBalance);
        //         setEscrowBalance(response.data.escrowBalance);

        //         localStorage.setItem('mainBalance', response.data.mainBalance);
        //         localStorage.setItem('escrowBalance', response.data.escrowBalance);
        //     } catch (error) {
        //         console.error('Error fetching account details:', error);
        //     }
        // };

        // fetchAccountDetails();

        // const interval = setInterval(fetchAccountDetails, 60000);

        // return () => clearInterval(interval);
    }, [mainBalance, escrowBalance, pinUrl]);


    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container flex w-full flex-col md:h-screen gap-4 md:py-12 items-center">
            <div className="flex w-fit flex-col justify-center items-center gap-2 border-b-2 py-8 max-w-full">
                <div className='md:flex flex gap-8 max-w-full mr-44 lg:mr-20 justify-start'>
                    <img src={logo} onClick={home} className='flex max-w-[180px] hover:cursor-pointer' alt="Logo" />
                    <FiMenu
                        onClick={() => setSideMenu(true)}
                        className="lg:hidden text-3xl cursor-pointer absolute top-12 right-8 "
                    />
                </div>

                {isSideMenuOpen && (
                    <div className="lg:hidden fixed inset-0 bg-blue-100 backdrop-blur-sm z-50">
                        <div className="absolute flex top-12 right-8 ">
                            <img src={logo} onClick={home} alt='Tranxactrust logo' className='flex max-w-[180px] hover:cursor-pointer mr-24' />
                            <GrClose onClick={() => setSideMenu(false)} className="text-black text-3xl cursor-pointer" />
                        </div>
                        <div className='mt-28 '>
                            <SideNav onClose={() => setSideMenu(false)} />
                        </div>
                    </div>
                )}

                <div className='flex rounded-2xl p-3 bg-blue-100 gap-4 w-full items-start justify-start mr-8 lg:mr-0'>
                    <img src={profile} alt="Profile" />
                    <div className='flex w-fit gap-2 justify-center items-center'>
                        <div className="w-fit flex flex-row gap-10">
                            <p className="w-full flex text-black font-bold font-lato text-xl leading-6 tracking-wider">
                                {user.username}
                            </p>
                            <Balance />
                        </div>
                    </div>
                </div>
                <div className='lg:hidden mr-8 w-full'><AccountDetails /></div>
            </div>
            <div className='hidden lg:block'>
                <SideNav />
            </div>
            <div className="hidden md:flex justify-center items-center p-6 mt-[100px] bg-guild-bg bg-no-repeat bg-contain">
                <button className="bg-blue-200 flex justify-center items-center rounded-lg px-12 py-2 gap-1">
                    <CiSquareAlert />
                    <p>Guide</p>
                </button>
            </div>

        </div>
    );
}

