import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TbCurrencyNaira } from "react-icons/tb";


export function Balance() {
    const [isOpen, setIsOpen] = useState(false);
    const [mainBalance, setMainBalance] = useState(localStorage.getItem('mainAccountBalance') || '');
    const [escrowBalance, setEscrowBalance] = useState(localStorage.getItem('escrowAccountBalance') || '');
  

    const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    useEffect(() => {
     
    }, []);



    return (
        <div className="dashboard-container">
            <div
                className="relative inline-block w-full text-left"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div>
                    <button
                        type="button"
                        className="inline-flex w-full rounded-md border border-blue-200 shadow-sm px-4 py-2 bg-blue-100 text-sm font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
                    >
                        Balance
                        <svg
                            className="-mr-5 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                {isOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5">
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <a className="flex flex-row px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100" role="menuitem">Wallet: <TbCurrencyNaira className="text-xl" />{mainBalance}</a>

                            <a className="flex flex-row px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100" role="menuitem">Escrow: <TbCurrencyNaira className="text-xl" />{escrowBalance}</a>

                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
