import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilledBtn from '../../../Reuseables/Buttons/FilledBtn/FilledBtn';
import { useUser } from '../../../userContext/UserContext';
import './ConfirmPin.css'; 



const ConfirmPin = () => {
  const navigate = useNavigate();
  const [walletPin, setWalletPin] = useState('');
  const accountNumber = localStorage.getItem('accountNumber');
  const accountName = localStorage.getItem('accountName');
  const selectedBank = localStorage.getItem('selectedBank');
  const amount = localStorage.getItem('amount');
  const description = localStorage.getItem('description');
  const referenceId = localStorage.getItem('referenceId');
  const client = JSON.parse(localStorage.getItem('client')); 
  const senderAccountName = localStorage.getItem('senderAccountName'); 
  const { user } = useUser();
  const { username: senderUsername } = user;

  const notifyError = (message) => toast.error(message, { className: 'toast-error' });
  const notifySuccess = (message) => toast.success(message, { className: 'toast-success' });

  const handleConfirmTransfer = async () => {
    const raw = JSON.stringify({
      senderAccountNumber: accountNumber, 
      senderAccountName: senderAccountName, 
      recipientAccount_number: accountNumber,
      recipientAccount_name: accountName,
      secretPin: walletPin,
      amount: Number(amount), 
      narration: description,
      bank_code: selectedBank,
      reference: referenceId,
      client: client, 
    });

    const requestOptions = {
      method: 'POST',
      body: raw,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/transfer`, requestOptions);
      const responseText = await response.text(); 
      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}, Text: ${responseText}`);
      }
      const result = await response.json();
      if (result.success) {
        notifySuccess('Transfer successful');
        navigate('/dashboard/send');
      } else {
        notifyError('Transfer failed');
      }
    } catch (error) {
      notifyError(`Failed to initiate transfer: ${error.message}`);
    }
  };

  return (
    <div className='w-full h-screen m-auto flex justify-center items-center bg-sign-up bg-cover bg-center'>
      <div className={"styles.container"}>
        <h2>Enter your PIN</h2>
        <input
          className={"styles.input"}
          placeholder="Enter 4-digit PIN"
          type="number"
          value={walletPin}
          onChange={(e) => setWalletPin(e.target.value)}
        />
        <div onClick={handleConfirmTransfer} disabled={walletPin.length !== 4}>
          <FilledBtn btn={'Confirm & Transfer'} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ConfirmPin;
