import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function SideSupport() {
    const [isOpen, setIsOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const navigate = useNavigate();
  
    const createPin = () => {
      navigate('/pin');
    };
  
    const accountDetails = () => {
      navigate('/transfer-details');
    };
  
    const handleMouseEnter = () => {
      clearTimeout(hoverTimeout);
      setIsOpen(true);
    };
  
    const handleMouseLeave = () => {
      const timeout = setTimeout(() => {
        setIsOpen(false);
      }, 200); 
      setHoverTimeout(timeout);
    };
  
    return (
      <div
        className="container w-full text-left"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* <div>
          <p
            type="text"
            className="inline-flex w-fit  px-4 py-2 text-[16px] font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
          >
            Support
          </p>
        </div> */}
  
        {/* {isOpen && ( */}
          <div className="container right-0 mt-2 w-full rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5"
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave} 
          >
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
             <p className='text-gray-400 font-bold ml-4'>Support</p>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                CUSTOMER SERVICE
              </a>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                REGISTER A REQUEST
              </a>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                CHARGEBACK GUIDES
              </a>
              <form method="POST" action="#">
                <button
                  onClick={accountDetails}
                  type="submit"
                  className="w-full text-left block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                  role="menuitem"
                >
                  SETTLEMENT GUIDES
                </button>
              </form>
            </div>
          </div>
        {/* )} */}
      </div>
    );
}

export default SideSupport