import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import nin from "../../assets/NIMC.png";
import NINWithFace from './VerifyWithFace';

function VerifyNIN() {
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('Unverified');

    const verifyNIN = () => {
        setVerificationStatus('Pending');
        setTimeout(() => {
            const isSuccess = true; 

            if (isSuccess) {
                setVerificationStatus('Verified');
            } else {
                setVerificationStatus('Failed');
            }
        }, 2000); 

        navigate('/nin-verification');
    }

    return (
        <div className="flex flex-col gap-2 item-start bg-indigo-100 bg-gradient-to-r from-lime-200 to-lime-600 lg:w-[80%] w-full rounded-lg">
            {/* <img src={nin} alt='nin' className='lg:w-[50px] lg:h-[50px] lg:ml-20 lg:my-12 w-[40px] h-[40px] my-2 ml-12' /> */}

            <div className="flex flex-col gap-4 items-center justify-between mt-2">
                <div className="flex gap-10 items-center">
                    <p className="text-lime-800 text-3xl w-full font-bold leading-10">NIN</p>
                </div>
                <p className={`w-fit h-[30px] font-medium rounded-[5px] p-1 border-1 border-blue-600 ${verificationStatus === 'Verified' ? 'text-green-600 bg-green-200' : verificationStatus === 'Pending' ? 'text-yellow-600 bg-yellow-200' : verificationStatus === 'Failed' ? 'text-red-600 bg-red-200' : 'text-gray-500 bg-lime-300'}`}>
                    {verificationStatus}
                </p>
                <button
                onClick={verifyNIN} 
                className='flex gap-3 items-center text-xl px-4 py-2 text-lime-700 font-medium rounded-[5px]'
                disabled={verificationStatus === 'Verified' || verificationStatus === 'Pending'}
                >
                    Verify Now
                </button>
            </div>

            <div>
                
            </div>
        </div>
    )
}

export default VerifyNIN











// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ninImage from "../../assets/NIMC.png"; 
// import NINWithFace from './VerifyWithFace';
// import Popup from './PopUpNin/PopupNin';

// function VerifyNIN() {
//     const navigate = useNavigate();

//     const [verificationStatus, setVerificationStatus] = useState('Unverified');
//     const [nin, setNin] = useState(''); 
//     const [message, setMessage] = useState('');
//     const [showPopup, setShowPopup] = useState(false);

//     const verifyNIN = () => {
//         console.log("Verifying NIN...");
//         setVerificationStatus('Pending');
//         setTimeout(() => {
//             const isSuccess = true;
//             if (isSuccess) {
//                 setVerificationStatus('Verified');
//             } else {
//                 setVerificationStatus('Failed');
//             }
//         }, 2000);
//     };

//     const handleNextStep = () => {
//         if (nin) {
//             setShowPopup(false);
//             console.log("Navigating to /nin-verification with NIN:", nin);
//             navigate('/nin-verification', { state: { nin } });
//         } else {
//             setMessage('Please enter your NIN.');
//         }
//     };

//     const closePopup = () => {
//         setShowPopup(false);
//     };

//     return (
//         <div className="flex flex-col gap-2 item-start bg-indigo-100 bg-gradient-to-r from-lime-200 to-lime-600 lg:w-[80%] w-full rounded-lg">
//             <div className="flex flex-col gap-4 items-center justify-between mt-2">
//                 <div className="flex gap-10 items-center">
//                     <p className="text-lime-800 text-3xl w-full font-bold leading-10">NIN</p>
//                 </div>
//                 <p className={`w-fit h-[30px] font-medium rounded-[5px] p-1 border-1 border-blue-600 ${verificationStatus === 'Verified' ? 'text-green-600 bg-green-200' : verificationStatus === 'Pending' ? 'text-yellow-600 bg-yellow-200' : verificationStatus === 'Failed' ? 'text-red-600 bg-red-200' : 'text-gray-500 bg-lime-300'}`}>
//                     {verificationStatus}
//                 </p>
//                 <button
//                     onClick={() => setShowPopup(true)}
//                     className='flex gap-3 items-center text-xl px-4 py-2 text-lime-700 font-medium rounded-[5px]'
//                     disabled={verificationStatus === 'Verified' || verificationStatus === 'Pending'}
//                 >
//                     Verify Now
//                 </button>
//             </div>

//             {showPopup && (
//                 <Popup 
//                     nin={nin} 
//                     setNin={setNin} 
//                     handleNextStep={handleNextStep} 
//                     message={message} 
//                     closePopup={closePopup} 
//                 />
//             )}
//         </div>
//     );
// }

// export default VerifyNIN;