import Home from "../pages/Home/Index";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import Trustmonie from "../Products/Trustmonie";
import About from "../pages/AboutUs/AboutUs";
import Investrust from "../Products/Investrust";
import { DashBoard } from "../pages/Dashboard/DashBoard";
import Shop from "../Products/Shop";
import { Store } from "../pages/Store/Store";
import NINWithFace from "../KYC/NINWithFace/VerifyWithFace";
import Layout from "../Layout/Layout";
import LoginLayout from "../Layout/LoginLayout";
import Logout from "../pages/Logout/Logout";
import CreatePin from "../Components/Wallets/CreatePin/CreatePin";
import AccountDetails from "../Components/Wallets/CreatePin/AccountDetails";
import TransferSuccessScreen from "../Components/Payment/Transactions/TransferSuccessScreen";
import Continue from "../Components/Payment/Transactions/Continue";
import Success from "../Components/Payment/Transactions/Success";
import ConfirmPin from "../Components/Wallets/CreatePin/ConfirmPin";
import VerifyNIN from "../KYC/NINWithFace/VerifyNIN";
import Settings from "../Components/Settings/Settings";
import PinDropdown from "../Reuseables/Dropdown/PinDropdown";
import { Profile } from "../Components/Settings/Profile";
import BankingPlus from "../Components/NavigationBar/MenuOptions/BankingPlus";
import { SecuritySettings } from "../Components/Settings/SecuritySettings";
import TransferScreen from "../Components/Payment/Transactions/ExternalTransferScreen";
import InternalTransfer from "../Components/Payment/Transactions/InternalTransfer";
import LocalTransfer from "../Components/Payment/Transactions/LocalTransfer";
import { element } from "prop-types";
import ReversePayment from "../Components/Payment/Transactions/ReversePayment";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
export const ROUTES = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "trustmonie",
        element: <Trustmonie />,
      },
      {
        path: "investrust",
        element: <Investrust />,
      },
      {
        path: "shop",
        element: <Shop />,
      },
      {
        path: "banking-plus",
        element: <BankingPlus />,
      }
    ],
  },
  {
    path: '/',
    element: <LoginLayout />,
    private: true,
    children: [
      {
        path: "home",
        element: <Home />,
        private: true,
      },
      {
        path: "about",
        element: <About />,
        private: false,
      },
      {
        path: "trustmonie",
        element: <Trustmonie />,
        private: false,
      },
      {
        path: "investrust",
        element: <Investrust />,
        private: false,
      },
      {
        path: "shop",
        element: <Shop />,
        private: false
      },

    ]
  },
  {
    path: "/login",
    element: <Login />,
    private: false,
  },
  {
    path:"/forgot-password",
    element:<ForgotPassword/>,

  },
  {
    path:"/reset-password",
    element:<ResetPassword/>,
    
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/dashboard",
    element: <DashBoard />,
    private: true,
  },
  {
    path: "/send",
    element: <Continue />,
    private: true,
  },
  {
    path: "/transfer",
    element: <LocalTransfer />,
    private: true,
  },

  {
    path: '/reverse',
    element: <ReversePayment />,
    private: true,
  },
  {
    path: "/success",
    element: <Success />,
    private: true,
  },
  {
    path: "/store",
    element: <Store />,
    private: true,
  },
  {
    path: "/local-transfer",
    element: <TransferScreen />,
    private: true,
  },
  {
    path: "/own-transfer",
    element: <InternalTransfer />,
    private: true,
  },
  {
    path: "/transfer-status",
    element: <TransferSuccessScreen />,
    private: true,
  },
  {
    path: "/bvn-verification",
    element: <NINWithFace />,
    private: true,
  },
  {
    path: "/",
    element: <VerifyNIN />,
    private: true,

  },
  {
    path: "/nin-verification",
    // element: <Popup />,
    element: <NINWithFace />,
    private: true,
  },
  {
    path: "/logout",
    element: <Logout />,
    private: true,
  },
  {
    path: "/pin",
    element: <CreatePin />,
    private: true,
  },
  {
    path: "/accountDetails",
    element: <AccountDetails />,
    private: true,
  },
  {
    path: "/confirmPin",
    element: <ConfirmPin />,
    private: true,
  },
  {
    path: "/settings",
    element: <Settings />,
    private: true,
  },
  {
    path: "/settings/security",
    element: <SecuritySettings />,
    private: true,
  },
  {
    path: "/settings/profile",
    element: <Profile />,
    private: true,
  }
];
