import React, { useState } from "react";
import continue_payment from "../../../images/Continue payment.png";
import { Link } from "react-router-dom";
import logo from "../../../images/Tranxact on.png";

const LocalTransfer = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState('');

    const handleChoice = (choice) => {
        setSelectedChoice(choice);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedChoice('');
    };


    return (
        <main className="w-full h-full justify-center items-center bg-cover bg-center">
            <div className="flex flex-col items-center justify-center min-h-screen">
                <img className="w-64 " src={logo} alt="Tranxactrust logo" />
                <img className="w-96 mt-2" src={continue_payment} alt="payment" />

                <h2 className="text-2xl mt-4 font-bold mb-4">Pay with Tranxactrust</h2>
                <div className="flex space-x-4">
                    <button
                        onClick={() => handleChoice('Local transfers are always accessible to the receiver.')}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                        Local Transfer
                    </button>
                    {/* <button
                        onClick={() => handleChoice('Escrow payment. The vendor you are paying to cannot access this money until they have sent your product to the delivery agent')}
                            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                        >
                            Escrow
                        </button> */}
                </div>

                {isPopupOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded shadow-lg flex flex-col">
                            <p className="mb-4">{selectedChoice}</p>
                            <div className="flex flex-row justify-evenly gap-4">
                                <Link to={'/local-transfer'}>
                                    <button
                                        className="bg-blue-500 text-white  px-4 py-2 rounded hover:bg-blue-700"
                                    >
                                    Accept
                                    </button>
                                </Link>
                                <button
                                    onClick={closePopup}
                                    className="bg-yellow-500 text-white items-center px-4 py-2 rounded hover:bg-red-700"
                                >
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </main>

    )
}

export default LocalTransfer;