import React from 'react';
import styles from "./ErrorPage.module.css";

const ErrorPage = ({ message }) => {
  return (
    <div className={styles.container}>
      <p className={styles.errorText}>Oops! An error occurred:</p>
      <p className={styles.errorMessage}>{message}</p>
    </div>
  );
};

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     textAlign: 'center',
//   },
//   errorText: {
//     fontSize: '20px',
//     fontWeight: 'bold',
//     marginBottom: '10px',
//   },
//   errorMessage: {
//     fontSize: '16px',
//     color: 'red',
//   },
// };

export default ErrorPage;
