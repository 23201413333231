// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import logo from "../../../images/Tranxact on.png";
// import AccountDetails from './AccountDetails';
// import PinManager from './PinManager';

// const CreatePinComponent = ({ onSuccess }) => {
//   const [pin, setPin] = useState(['', '', '', '']);
//   const [mainAccountNumber, setMainAccountNumber] = useState('');
//   const [escrowAccountNumber, setEscrowAccountNumber] = useState('');
//   const [isPinCreated, setIsPinCreated] = useState(false);
//   const inputsRef = useRef([]);

//   const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;

//   useEffect(() => {
//     const checkPinStatus = async () => {
//       try {
//         const token = localStorage.getItem('authToken');
//         console.log('Checking PIN status...');
//         const response = await axios.post(`${pinUrl}/api/v2/accountSetUp`, {}, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         console.log('Check Pin Status Response:', response.data);
//         setIsPinCreated(response.data.isPinCreated);
//         if (response.data.isPinCreated) {
//           setMainAccountNumber(response.data.mainAccountNumber);
//           setEscrowAccountNumber(response.data.escrowAccountNumber);
//         }
//       } catch (error) {
//         console.error('Error checking pin status:', error);
//       }
//     };
//     checkPinStatus(); 
//   }, [pinUrl]); 

//   const handleCreatePin = async () => {
//     try {
//       const token = localStorage.getItem('authToken');
//       const pinString = pin.join('');
//       const response = await axios.post(`${pinUrl}/api/v2/wallet/pin`, { pin: pinString }, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log('Create PIN Response:', response.data);
//       onSuccess(response.data);
//       setIsPinCreated(true);
//       fetchAccountNumber(token);
//     } catch (error) {
//       if (error.response) {
//         if (error.response.status === 409) {
//           console.error('PIN already exists:', error.response.data);
//         } else {
//           console.error('Request failed:', error.response.status);
//         }
//       } else if (error.request) {
//         console.error('Request error:', error.request);
//       } else {
//         console.error('General error:', error.message);
//       }
//     }
//   };

//   const fetchAccountNumber = async (token) => {
//     try {
//       console.log('Fetching account number...');
//       const response = await axios.post(`${pinUrl}/api/v2/accountSetUp`, {}, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       console.log('Account Number Response:', response.data);
//       setMainAccountNumber(response.data.mainAccountNumber);
//       setEscrowAccountNumber(response.data.escrowAccountNumber);
//     } catch (error) {
//       console.error('Error fetching account number:', error);
//     }
//   };

//   const handlePinChange = (e, index) => {
//     const newPin = [...pin];
//     newPin[index] = e.target.value;
//     setPin(newPin);

//     if (e.target.value && index < 3) {
//       inputsRef.current[index + 1].focus();
//     }
//   };

//   const handleBackspace = (e, index) => {
//     if (e.key === 'Backspace' && !pin[index] && index > 0) {
//       inputsRef.current[index - 1].focus();
//     }
//   };

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
//       <img src={logo} alt="Tranxactrust logo" className="w-[180px]" />
//       {!isPinCreated ? (
//         <div className="bg-white p-6 rounded shadow-md">
//           <h2 className="text-xl mb-4">Create Your 4-Digit PIN</h2>
//           <div className="flex space-x-2">
//             {pin.map((value, index) => (
//               <input
//                 key={index}
//                 type="password"
//                 value={value}
//                 onChange={(e) => handlePinChange(e, index)}
//                 onKeyDown={(e) => handleBackspace(e, index)}
//                 maxLength="1"
//                 ref={(el) => (inputsRef.current[index] = el)}
//                 className="w-12 h-12 text-center border border-gray-300 rounded focus:outline-none"
//               />
//             ))}
//           </div>
//           <button
//             onClick={handleCreatePin}
//             disabled={pin.includes('')}
//             className="mt-4 px-4 py-2 ml-12 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
//           >
//             Create PIN
//           </button>
//         </div>
//       ) : (
//         <>
//           <AccountDetails
//             mainAccountNumber={mainAccountNumber}
//             escrowAccountNumber={escrowAccountNumber}
//           />
//           <PinManager
//             mainAccountNumber={mainAccountNumber}
//             escrowAccountNumber={escrowAccountNumber}
//           />
//         </>
//       )}
//     </div>
//   );
// };

// export default CreatePinComponent;








import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from "../../../images/Tranxact on.png";
import AccountDetails from './AccountDetails';
import PinManager from './PinManager';
import { useNavigate } from 'react-router-dom';

const CreatePinComponent = ({ onSuccess }) => {
  const [pin, setPin] = useState(['', '', '', '']);
  const [mainAccountNumber, setMainAccountNumber] = useState('');
  const [escrowAccountNumber, setEscrowAccountNumber] = useState('');
  const [isPinCreated, setIsPinCreated] = useState(false);
  const inputsRef = useRef([]);

  const navigate = useNavigate();

  const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  useEffect(() => {
    const checkPinStatus = async () => {
      try {
        const token = localStorage.getItem('authToken');
        console.log('Checking PIN status...');
        const response = await axios.post(`${pinUrl}/api/v2/accountSetUp`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Check Pin Status Response:', response.data);
        setIsPinCreated(response.data.isPinCreated);
        if (response.data.isPinCreated) {
          setMainAccountNumber(response.data.mainAccountNumber);
          setEscrowAccountNumber(response.data.escrowAccountNumber);
          localStorage.setItem('mainAccountNumber', response.data.mainAccountNumber);
          localStorage.setItem('escrowAccountNumber', response.data.escrowAccountNumber);
        }
      } catch (error) {
        console.error('Error checking pin status:', error);
      }
    };
    checkPinStatus();
  }, [pinUrl]);

  const handleCreatePin = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const pinString = pin.join('');
      console.log('Creating PIN with:', pinString);

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      const data = { pin: pinString };

      const response = await axios.post(`${pinUrl}/api/v2/wallet/pin`, data, { headers });
      console.log('Create PIN Response:', response.data);
      onSuccess(response.data);
      setIsPinCreated(true);
      fetchAccountNumber(token);
      
    } catch (error) {
      if (error.response) {
        if (error.response.status === 409) {
          console.error('PIN already exists:', error.response.data);
        } else if (error.response.status === 417) {
          console.error('Expectation Failed:', error.response.data);
        } else {
          console.error('Request failed:', error.response.status, error.response.data);
        }
      } else if (error.request) {
        console.error('Request error:', error.request);
      } else {
        console.error('General error:', error.message);
      }
    }
  };

  const fetchAccountNumber = async (token) => {
    try {
      console.log('Fetching account number...');
      const response = await axios.post(`${pinUrl}/api/v2/accountSetUp`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Account Number Response:', response.data);
      setMainAccountNumber(response.data.mainAccountNumber);
      setEscrowAccountNumber(response.data.escrowAccountNumber);
      localStorage.setItem('mainAccountNumber', response.data.mainAccountNumber);
      localStorage.setItem('escrowAccountNumber', response.data.escrowAccountNumber);
    } catch (error) {
      console.error('Error fetching account number:', error);
    }
  };

  const handlePinChange = (e, index) => {
    const newPin = [...pin];
    newPin[index] = e.target.value;
    setPin(newPin);

    if (e.target.value && index < 3) {
      inputsRef.current[index + 1].focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace' && !pin[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={logo} alt="Tranxactrust logo" className="w-[180px]" />
      {!isPinCreated ? (
        <div className="bg-white p-6 rounded shadow-md">
          <h2 className="text-xl mb-4">Create Your 4-Digit PIN</h2>
          <div className="flex space-x-2">
            {pin.map((value, index) => (
              <input
                key={index}
                type="password"
                value={value}
                onChange={(e) => handlePinChange(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                maxLength="1"
                ref={(el) => (inputsRef.current[index] = el)}
                className="w-12 h-12 text-center border border-gray-300 rounded focus:outline-none"
              />
            ))}
          </div>
          <button
            onClick={handleCreatePin}
            disabled={pin.includes('')}
            className="mt-4 px-4 py-2 ml-12 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          >
            Create PIN
          </button>
        </div>
      ) : (
        <>
          <AccountDetails
            mainAccountNumber={mainAccountNumber}
            escrowAccountNumber={escrowAccountNumber}
          />
          <PinManager
            mainAccountNumber={mainAccountNumber}
            escrowAccountNumber={escrowAccountNumber}
          />
        </>
      )}
    </div>
  );
};

export default CreatePinComponent;
