import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AccountDetails = () => {
  const [mainAccountNumber, setMainAccountNumber] = useState(localStorage.getItem('mainAccountNumber') || '');
  const [escrowAccountNumber, setEscrowAccountNumber] = useState(localStorage.getItem('escrowAccountNumber') || '');
  const [mainBalance, setMainBalance] = useState(localStorage.getItem('mainBalance') || '');
  const [escrowBalance, setEscrowBalance] = useState(localStorage.getItem('escrowBalance') || '');

  const [showDetails, setShowDetails] = useState(true);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const pinUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  const fetchAccountDetails = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found.');
        return;
      }

      console.log('Fetching account details...');
      const response = await axios.get(`${pinUrl}/api/v2/wallet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Account Details Respons:', response.data);

      setMainAccountNumber(response.data.mainAccountNumber);
      setEscrowAccountNumber(response.data.escrowAccountNumber);
      setMainBalance(response.data.mainBalance);
      setEscrowBalance(response.data.escrowBalance);

      localStorage.setItem('mainAccountNumber', response.data.mainAccountNumber);
      localStorage.setItem('escrowAccountNumber', response.data.escrowAccountNumber);
      localStorage.setItem('mainAccountBalance', response.data.mainBalance);
      localStorage.setItem('escrowAccountBalance', response.data.escrowBalance);
    } catch (error) {
      console.error('Error fetching account details:', error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAccountDetails();

    })();

     const interval = setInterval(fetchAccountDetails, 30000);
      return () => clearInterval(interval);
  },[]);


  return (
    <div className="bg-blue-100 p-6 w-full rounded shadow-md">
      {showDetails ? (
        <>
          <p className='text-gray-500'>Main account: <span className='text-gray-600 font-bold'>{mainAccountNumber}</span> (Wema)</p>
          <p className='text-gray-500'>Escrow account: <span className='text-gray-600 font-bold'>{escrowAccountNumber}</span> (Wema)</p>
        </>
      ) : (
        <p>Account details are hidden</p>
      )}
      <button
        onClick={handleToggleDetails}
        className="mt-4 px-4 py-2 bg-blue-500 w-full text-white rounded hover:bg-blue-600 focus:outline-none"
      >
        {showDetails ? 'Hide' : 'Show'} Account Details
      </button>
    </div>
  );
};

export default AccountDetails;

