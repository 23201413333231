import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BsFilePost } from "react-icons/bs";
import { GiCash } from "react-icons/gi";
import { IoReceiptSharp } from "react-icons/io5";
import logo from "../../../images/New_trxt-.png";



function Payment() {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const navigate = useNavigate();

  const createPin = () => {
    navigate('/pin');
  };

  const accountDetails = () => {
    navigate('/transfer-details');
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, 200); 
    setHoverTimeout(timeout);
  };

  return (
    <div
      className="relative inline-block w-full text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <p
          type="text"
          className="inline-flex w-fit px-4 py-2 text-[16px] font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
        >
          Payments
        </p>
        
      </div>

      {isOpen && (
        <div className="flex origin-top-right absolute m-0 mt-2 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5"
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave} 
        >
          <div className="py-1 w-96" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className='flex w-[340px]'>
              <div className="ml-3 mt-4">
                < img src={logo} alt='Tranxactrust logo' className='w-[40px] h-[30px] ' />
              </div>
              <div>
              <a
                onClick={createPin}
                className="flex px-4 mt-2 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                PAYMENT GATEWAY
                </a>
                <p className="flex px-4 py-2 font-bold text-sm text-gray-400 ">
                  Accept payment on your website and app with ease
                </p>
              </div>
            </div>

            <div className='gap-8 mt-2 py-0 justify-evenly'>
              <div className="ml-4 ">
                <svg className="z-index-1 mt-1 absolute fill-slate-100 flex-shrink-0 " width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#0887ef" />
                </svg>
                <svg className="z-index-1 mt-1 absolute w-[25px] h-[25px] fill-slate-100 flex-shrink-0 " width="14" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.8672 20.1771L5.59375 27.4688L12.8672 34.7605V29.2917H25.6458V25.6459H12.8672V20.1771ZM38.4062 16.5313L31.1328 9.23962V14.7084H18.3542V18.3542H31.1328V23.823L38.4062 16.5313Z" fill="#f6f8fa" />
                </svg>
              </div>
              <a
                onClick={createPin}
                className="flex px-4 mt-0 ml-10 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >

                REVERSE PAYMENT
              </a>
              <p className="flex px-4 ml-10 py-2 font-bold text-sm text-gray-400 ">
                  Reverse payment for every failed delivery.
                </p>
            </div>

            <div className='gap-8 mt-2 py-0 justify-evenly'>
              <div className="ml-4 ">
                <svg className="z-index-1 absolute fill-slate-100 flex-shrink-0 " width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="12" fill="#0887ef" />
                </svg>
                <svg className="z-index-1 absolute w-[22px] h-[22px] fill-slate-100 flex-shrink-0 " width="44" height="44" viewBox="0 0 38 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.6489 28.5539C24.5846 28.4903 24.4979 28.4546 24.4075 28.4546C24.3171 28.4546 24.2303 28.4903 24.1661 28.5539L19.2015 33.5185C16.9029 35.8171 13.0235 36.0606 10.4857 33.5185C7.94354 30.9764 8.18707 27.1013 10.4857 24.8027L15.4503 19.8381C15.5827 19.7056 15.5827 19.4877 15.4503 19.3553L13.7498 17.6548C13.6856 17.5912 13.5988 17.5556 13.5084 17.5556C13.418 17.5556 13.3313 17.5912 13.267 17.6548L8.30243 22.6194C4.68793 26.2339 4.68793 32.0829 8.30243 35.6932C11.9169 39.3034 17.7659 39.3077 21.3762 35.6932L26.3408 30.7286C26.4732 30.5961 26.4732 30.3782 26.3408 30.2458L24.6489 28.5539ZM35.6975 8.30243C32.0829 4.68793 26.2339 4.68793 22.6237 8.30243L17.6548 13.267C17.5912 13.3313 17.5556 13.418 17.5556 13.5084C17.5556 13.5988 17.5912 13.6856 17.6548 13.7498L19.351 15.446C19.4835 15.5784 19.7014 15.5784 19.8338 15.446L24.7984 10.4814C27.097 8.1828 30.9764 7.93927 33.5142 10.4814C36.0563 13.0235 35.8128 16.8986 33.5142 19.1972L28.5496 24.1618C28.486 24.226 28.4503 24.3128 28.4503 24.4032C28.4503 24.4936 28.486 24.5804 28.5496 24.6446L30.2501 26.345C30.3825 26.4775 30.6004 26.4775 30.7329 26.345L35.6975 21.3804C39.3077 17.7659 39.3077 11.9169 35.6975 8.30243ZM26.1912 16.0313C26.127 15.9677 26.0402 15.932 25.9498 15.932C25.8594 15.932 25.7727 15.9677 25.7084 16.0313L16.0313 25.7042C15.9677 25.7684 15.932 25.8552 15.932 25.9456C15.932 26.036 15.9677 26.1227 16.0313 26.187L17.7232 27.8788C17.8557 28.0113 18.0736 28.0113 18.206 27.8788L27.8788 18.206C28.0113 18.0735 28.0113 17.8557 27.8788 17.7232L26.1912 16.0313Z" fill="#f6f8fa" />
                </svg>
              </div>
              <a
                onClick={createPin}
                className="flex px-4 ml-10 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                PAYMENT LINK
              </a>
              <p className="flex px-4 ml-10 py-2 font-bold text-sm text-gray-400 ">
                  Generate payment link and share with customers on social media.
                </p>
            </div>
            <div className='flex mt-2 py-0 '>
              <div className="ml-4 ">
                <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" stroke="#0887ef" stroke-width="3" fill="none" />
                  <path d="M7 12.5L10 15.5L17 8.5" stroke="#0887ef" stroke-width="3" fill="none" />
                </svg>
              </div>
              <div>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                VERIFY ESCROW ACCOUNT
              </a>
              <p className="flex px-4 py-2 font-bold text-sm text-gray-400 ">
                  Confirm that account number is escrow account type before 
                  paying your vendor.
                </p>
                </div>
            </div>

          </div>

          <div className='gap-8 w-[340px] mt-4 py-0 justify-evenly'>

            <div className=" flex mr-20">
              <BsFilePost className='text-[#0887ef] w-[42px] h-[42px] ' />
              <div>
              <a
                onClick={createPin}
                className="flex px-4 py-2 mt-1.5 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                TRANXACTRUST POS
              </a>
              <p className="flex px-4 py-2 font-bold text-sm text-gray-400 ">
                  Point of Sale devices with multiple payment methods for in-store payment.
                </p>
                </div>
            </div>

            <div className=" mt-2 flex mr-20">
              <GiCash className='text-[#0887ef] w-[35px] h-[35px] ' />
              
              <div>
                <a
                onClick={createPin}
                className="flex px-4 py-2 mr-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                INSTANT CASHOUTS
              </a>
              <p className="flex px-4 py-2 font-bold text-sm text-gray-400 ">
                  Receive & withdraw instant payment from escrow wallet.
                </p>
                </div>
            </div>

            <div className=" mt-2 flex mr-40">
              <IoReceiptSharp className='text-[#0887ef] w-[35px] h-[35px] ' />
              <div>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                VOUCHER
              </a>
              <p className="flex px-4 py-2 font-bold text-sm text-gray-400 ">
                  Access forward-dated voucher as payment.
                </p>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment