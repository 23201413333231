import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Pricing() {
    const [isOpen, setIsOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    const navigate = useNavigate();
  
    const pricing = () => {
      navigate('/pricing');
    };
  
  
    const handleMouseEnter = () => {
      clearTimeout(hoverTimeout);
      setIsOpen(true);
    };
  
    const handleMouseLeave = () => {
      const timeout = setTimeout(() => {
        setIsOpen(false);
      }, 200); 
      setHoverTimeout(timeout);
    };
  
    return (
      <div
        className="relative inline-block w-full text-left"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <p
            type="text" onClick={pricing}
            className="inline-flex w-fit px-4 py-2 text-[16px] font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
          >
            Pricing
          </p>
        </div>
      </div>
    );
}

export default Pricing