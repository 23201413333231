import React from "react";
import { Link } from "react-router-dom";


const Escrow = () => {
    

    return(
        <div className="flex flex-col gap-2 item-start w-[100%]">

        <div className="flex gap-6 items-center justify-between">

            <div className="flex gap-10 items-center">
                <p className="text-gray-900 text-xl font-bold leading-10">Escrow Wallet</p>
            </div>

            <Link to={'/reverse'}><button className='flex gap-3 items-center px-4 mt-4 py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>
                Reverse Payment
            </button>
            </Link>

            <Link to={'/send'}><button className='flex gap-3 items-center px-4 mt-4 py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>
                Quick Cashout
            </button>
            </Link>
        </div>

        <div>

        </div>

    </div>
    )
};

export default Escrow;