// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ErrorPage from './ErrorPage';
// import WithdrawalSuccessScreen from './TransferSuccessScreen';
// import styles from './TransferScreen.module.css';
// import logo from '../../../images/Tranxact on.png';
// import FilledBtn from '../../../Reuseables/Buttons/FilledBtn/FilledBtn';
// import SelectBank from './SelectBank';
// import ConfirmPin from '../../Wallets/CreatePin/ConfirmPin';

// const TransferScreen = () => {
//   const navigate = useNavigate();
//   const [senderUsername, setSenderUsername] = useState('');
//   const [withdrawalStatus, setWithdrawalStatus] = useState(null);
//   const [accountNumber, setAccountNumber] = useState('');
//   const [selectedBank, setSelectedBank] = useState('');
//   const [accountName, setAccountName] = useState('........................');
//   const [currency, setCurrency] = useState('Nigerian Naira');
//   const [amount, setAmount] = useState('');
//   const [description, setDescription] = useState('');
//   const [walletPin, setWalletPin] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(false);
//   const [errorMessage, setErrorMessage] = useState({
//     amount: '',
//     accountNumber: '',
//     walletPin: '',
//   });
//   const [isTransferDisabled, setIsTransferDisabled] = useState(true);
//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [currentStep, setCurrentStep] = useState(1);

//   const banksData = [
//     { "label": "Access Bank", "value": "044" },
//     { "label": "Access Bank (Diamond)", "value": "063" },
//     { "label": "ALAT by WEMA", "value": "035A" },
//     { "label": "Carbon", "value": "565" },
//     { "label": "Citibank Nigeria", "value": "023" },
//     { "label": "Ecobank Nigeria", "value": "050" },
//     { "label": "Fidelity Bank", "value": "070" },
//     { "label": "First Bank of Nigeria", "value": "011" },
//     { "label": "First City Monument Bank", "value": "214" },
//     { "label": "Globus Bank", "value": "00103" },
//     { "label": "Guaranty Trust Bank", "value": "058" },
//     { "label": "Heritage Bank", "value": "030" },
//     { "label": "Keystone Bank", "value": "082" },
//     { "label": "Kuda Bank", "value": "50211" },
//     { "label": "Lotus Bank", "value": "303" },
//     { "label": "OPay Digital Services Limited (OPay)", "value": "999992" },
//     { "label": "Paga", "value": "100002" },
//     { "label": "PalmPay", "value": "999991" },
//     { "label": "Parallex Bank", "value": "104" },
//     { "label": "Polaris Bank", "value": "076" },
//     { "label": "Providus Bank", "value": "101" },
//     { "label": "Stanbic IBTC Bank", "value": "221" },
//     { "label": "Standard Chartered Bank", "value": "068" },
//     { "label": "Sterling Bank", "value": "232" },
//     { "label": "Suntrust Bank", "value": "100" },
//     { "label": "Union Bank of Nigeria", "value": "032" },
//     { "label": "United Bank For Africa", "value": "033" },
//     { "label": "Unity Bank", "value": "215" },
//     { "label": "VFD Microfinance Bank Limited", "value": "566" },
//     { "label": "Wema Bank", "value": "035" },
//     { "label": "Zenith Bank", "value": "057" }
//   ];

//   const fetchAccountName = () => {
//     setIsLoading(true);
//     const url = `https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${selectedBank}`;
//     fetch(url, {
//       method: 'GET',
//       headers: {
//         'Authorization': 'Bearer sk_test_9a115c67b66285fc18c219e0e1aa83d290cfd2ad',
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setAccountName(data.data.account_name);
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         setAccountName('Invalid Account Details');
//         setIsLoading(false);
//       });
//   };

//   useEffect(() => {
//     if (accountNumber.length === 10 && selectedBank) {
//       fetchAccountName();
//     }
//   }, [accountNumber, selectedBank]);

//   useEffect(() => {
//     const isFormValid = () => {
//       return accountNumber.length === 10 && selectedBank && amount > 0 && description;
//     };
//     setIsTransferDisabled(!isFormValid());
//   }, [accountNumber, selectedBank, amount, description]);

//   useEffect(() => {
//     const fetchSenderUsername = async () => {
//       try {
//         const username = await localStorage.getItem('senderUsername');
//         setSenderUsername(username);
//       } catch (error) {
//         console.error('Error retrieving sender username:', error);
//       }
//     };

//     fetchSenderUsername();
//   }, []);

//   const handleNextStep = async () => {
//     if (currentStep === 1 && !selectedBank) {
//       setErrorMessage((prev) => ({ ...prev, selectedBank: 'Please select a bank' }));
//       return;
//     } else if (currentStep === 2 && accountNumber.length !== 10) {
//       setErrorMessage((prev) => ({ ...prev, accountNumber: 'Account number must be 10 digits long.' }));
//       return;
//     } else if (currentStep === 3 && (isNaN(amount) || amount <= 0)) {
//       setErrorMessage((prev) => ({ ...prev, amount: 'Please enter a valid amount.' }));
//       return;
//     }
  
//     if (currentStep === 3) {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/transfer`);
//         const result = await response.json();
//         if (response.ok && result.referenceId) {
//           localStorage.setItem('referenceId', result.referenceId);
//           setShowConfirmation(true);
//         } else {
//           setErrorMessage('Failed to generate reference ID');
//         }
//       } catch (error) {
//         console.error('Error generating reference ID:', error);
//         setErrorMessage('Failed to generate reference ID');
//       }
//     } else {
//       setCurrentStep(currentStep + 1);
//     }
//   };  

//   const handleConfirmTransfer = () => {
//     localStorage.setItem('accountNumber', accountNumber);
//     localStorage.setItem('accountName', accountName);
//     localStorage.setItem('selectedBank', selectedBank);
//     localStorage.setItem('amount', amount);
//     localStorage.setItem('description', description);
//     // return(
//     //   <ConfirmPin />
//     // )
//     navigate('/confirmPin');
//   };

//   return (
//     <div className='w-full h-screen m-auto flex justify-center items-center bg-sign-up bg-cover bg-center'>
//       {error ? (
//         <ErrorPage message="Incorrect Details" />
//       ) : (
//         <div className={styles.container}>
//           <img src={logo} alt='Tranxactrust logo' className='w-[220px]' />

//           <div className={styles.header}>
//             <p className={styles.headerText}>Make a withdrawal</p>
//           </div>
//           {withdrawalStatus && (
//             <WithdrawalSuccessScreen status={withdrawalStatus} onPressOk={() => navigate('/dashboard')} />
//           )}
//           {!withdrawalStatus && (
//             <div className={styles.inputContainer}>
//               {currentStep >= 1 && (
//                 <>
//                   <SelectBank options={banksData} onSelect={(bank) => setSelectedBank(bank)} />
//                   {currentStep === 1 && (
//                     <div onClick={handleNextStep}><FilledBtn btn={'Next'} /></div>
//                   )}
//                 </>
//               )}

//               {currentStep >= 2 && (
//                 <>
//                   <input
//                     className={styles.input}
//                     placeholder="Enter bank account number"
//                     type="number"
//                     value={accountNumber}
//                     onChange={(e) => setAccountNumber(e.target.value)}
//                   />
//                   <p className={styles.errorText}>{errorMessage.accountNumber}</p>
//                   <p style={{ fontSize: 20 }}>Account Name: {accountName}</p>
//                   {currentStep === 2 && (
//                     <div onClick={handleNextStep}><FilledBtn btn={'Next'} /></div>
//                   )}
//                 </>
//               )}

//               {currentStep >= 3 && (
//                 <>
//                   <select className={styles.input} value={currency} onChange={(e) => setCurrency(e.target.value)}>
//                     <option value="Nigerian Naira">Nigerian Naira</option>
//                     {/* Add more currency options here */}
//                   </select>

//                   <input
//                     className={styles.input}
//                     placeholder="Enter amount"
//                     type="number"
//                     value={amount}
//                     onChange={(e) => setAmount(e.target.value)}
//                   />
//                   <p className={styles.errorText}>{errorMessage.amount}</p>

//                   <input
//                     className={styles.input}
//                     placeholder="Payment description"
//                     type="text"
//                     value={description}
//                     onChange={(e) => setDescription(e.target.value)}
//                   />

//                   <div onClick={() => setShowConfirmation(true)}
//                     disabled={isTransferDisabled}><FilledBtn
//                       btn={'Continue'}
//                     />
//                   </div>
//                 </>
//               )}

//               {showConfirmation && (
//                 <div className={styles.confirmationPopup}>
//                   <h3>Confirm Transfer</h3>
//                   <p>Account Number: {accountNumber}</p>
//                   <p>Bank: {selectedBank}</p>
//                   <p>Account Name: {accountName}</p>
//                   <p>Currency: {currency}</p>
//                   <p>Amount: {amount}</p>
//                   <p>Description: {description}</p>
//                   <p className={styles.errorText}>{errorMessage.walletPin}</p>
//                   <div onClick={handleConfirmTransfer}><FilledBtn btn={'Confirm & Transfer'} /></div>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default TransferScreen;








import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import WithdrawalSuccessScreen from './TransferSuccessScreen';
import styles from './TransferScreen.module.css';
import logo from '../../../images/Tranxact on.png';
import FilledBtn from '../../../Reuseables/Buttons/FilledBtn/FilledBtn';
import SelectBank from './SelectBank';

const TransferScreen = () => {
  const navigate = useNavigate();
  const [senderUsername, setSenderUsername] = useState('');
  const [withdrawalStatus, setWithdrawalStatus] = useState(null);
  const [accountNumber, setAccountNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [accountName, setAccountName] = useState('........................');
  const [currency, setCurrency] = useState('Nigerian Naira');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [walletPin, setWalletPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    amount: '',
    escrowAccountNumber: '',
    walletPin: '',
  });
  const [isTransferDisabled, setIsTransferDisabled] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const banksData = [
    { "label": "Access Bank", "value": "044" },
    { "label": "Access Bank (Diamond)", "value": "063" },
    { "label": "ALAT by WEMA", "value": "035A" },
    { "label": "Carbon", "value": "565" },
    { "label": "Citibank Nigeria", "value": "023" },
    { "label": "Ecobank Nigeria", "value": "050" },
    { "label": "Fidelity Bank", "value": "070" },
    { "label": "First Bank of Nigeria", "value": "011" },
    { "label": "First City Monument Bank", "value": "214" },
    { "label": "Globus Bank", "value": "00103" },
    { "label": "Guaranty Trust Bank", "value": "058" },
    { "label": "Heritage Bank", "value": "030" },
    { "label": "Keystone Bank", "value": "082" },
    { "label": "Kuda Bank", "value": "50211" },
    { "label": "Lotus Bank", "value": "303" },
    { "label": "OPay Digital Services Limited (OPay)", "value": "999992" },
    { "label": "Paga", "value": "100002" },
    { "label": "PalmPay", "value": "999991" },
    { "label": "Parallex Bank", "value": "104" },
    { "label": "Polaris Bank", "value": "076" },
    { "label": "Providus Bank", "value": "101" },
    { "label": "Stanbic IBTC Bank", "value": "221" },
    { "label": "Standard Chartered Bank", "value": "068" },
    { "label": "Sterling Bank", "value": "232" },
    { "label": "Suntrust Bank", "value": "100" },
    { "label": "Union Bank of Nigeria", "value": "032" },
    { "label": "United Bank For Africa", "value": "033" },
    { "label": "Unity Bank", "value": "215" },
    { "label": "VFD Microfinance Bank Limited", "value": "566" },
    { "label": "Wema Bank", "value": "035" },
    { "label": "Zenith Bank", "value": "057" }
  ];

  const fetchAccountName = () => {
    setIsLoading(true);
    const url = `https://api.paystack.co/bank/resolve?account_number=${accountNumber}&bank_code=${selectedBank}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer sk_test_9a115c67b66285fc18c219e0e1aa83d290cfd2ad',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAccountName(data.data.account_name);
        setIsLoading(false);
      })
      .catch((err) => {
        setAccountName('Invalid Account Details');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (accountNumber.length === 10 && selectedBank) {
      fetchAccountName();
    }
  }, [accountNumber, selectedBank]);

  useEffect(() => {
    const isFormValid = () => {
      return accountNumber.length === 10 && selectedBank && amount > 0 && description;
    };
    setIsTransferDisabled(!isFormValid());
  }, [accountNumber, selectedBank, amount, description]);

  useEffect(() => {
    const fetchSenderUsername = async () => {
      try {
        const username = await localStorage.getItem('senderUsername');
        setSenderUsername(username);
      } catch (error) {
        console.error('Error retrieving sender username:', error);
      }
    };

    fetchSenderUsername();
  }, []);

  const handleNextStep = async () => {
    if (currentStep === 1 && !selectedBank) {
      setErrorMessage((prev) => ({ ...prev, selectedBank: 'Please select a bank' }));
      return;
    } else if (currentStep === 2 && accountNumber.length !== 10) {
      setErrorMessage((prev) => ({ ...prev, accountNumber: 'Account number must be 10 digits long.' }));
      return;
    } else if (currentStep === 3 && (isNaN(amount) || amount <= 0)) {
      setErrorMessage((prev) => ({ ...prev, amount: 'Please enter a valid amount.' }));
      return;
    }
  
    if (currentStep === 3) {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/others/transfer`);
        const result = await response.json();
        if (response.ok ) {
          setShowConfirmation(true);
        } else {
          setErrorMessage('Transaction failed');
        }
      } catch (error) {
        console.error('Error performing transaction:', error);
        setErrorMessage('Transaction failed');
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };  

  const handleChoice = (choice) => {
    setIsPopupOpen(true);
};

const closePopup = () => {
    setIsPopupOpen(false);
};

  const handleConfirmTransfer = async () => {
    setIsPopupOpen(true);

    setIsLoading(true);

    const transferData = {
      accountNumber: accountNumber,  
      amount: parseFloat(amount),
      walletPin: walletPin,
      narration: description,
      bankCode: selectedBank,
      accountName: accountName

    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/others/transfer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transferData),
      });

      const result = await response.json();
      if (response.ok) {
        setWithdrawalStatus('success');
        setIsLoading(false);
        navigate('/transfer-success');
      } else {
        setError(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error making transfer:', error);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <div className='w-full h-screen m-auto flex justify-center items-center bg-sign-up bg-cover bg-center'>
      {error ? (
        <ErrorPage message="Incorrect Details" />
      ) : (
        <div className={styles.container}>
          <img src={logo} alt='Tranxactrust logo' className='w-[220px]' />

          <div className={styles.header}>
            <p className={styles.headerText}>Send money</p>
          </div>
          {withdrawalStatus && (
            <WithdrawalSuccessScreen status={withdrawalStatus} onPressOk={() => navigate('/dashboard')} />
          )}
          {!withdrawalStatus && (
            <div className={styles.inputContainer}>
              {currentStep >= 1 && (
                <>
                  <SelectBank options={banksData} onSelect={(bank) => setSelectedBank(bank)} />
                  {currentStep === 1 && (
                    <div onClick={handleNextStep}><FilledBtn btn={'Next'} /></div>
                  )}
                </>
              )}

              {currentStep >= 2 && (
                <>
                  <input
                    className={styles.input}
                    placeholder="Enter bank account number"
                    type="number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                  <p className={styles.errorText}>{errorMessage.accountNumber}</p>
                  <p style={{ fontSize: 20 }}>Account Name: {accountName}</p>
                  {currentStep === 2 && (
                    <div onClick={handleNextStep}><FilledBtn btn={'Next'} /></div>
                  )}
                </>
              )}

              {currentStep >= 3 && (
                <>
                  <select className={styles.input} value={currency} onChange={(e) => setCurrency(e.target.value)}>
                    <option value="Nigerian Naira">NGN</option>
                    {/* Add more currency options here */}
                  </select>

                  <input
                    className={styles.input}
                    placeholder="Enter amount"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <p className={styles.errorText}>{errorMessage.amount}</p>

                  <input
                    className={styles.input}
                    placeholder="Description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />

                  <div onClick={() => setShowConfirmation(true)} disabled={isTransferDisabled}>
                    <FilledBtn btn={'Continue'} />
                  </div>
                </>
              )}


              {showConfirmation && (
                <div className={styles.confirmationPopup}>
                  <h3>Confirm Transfer</h3>
                  <p>Account Number: {accountNumber}</p>
                  <p>Bank: {selectedBank}</p>
                  <p>Account Name: {accountName}</p>
                  <p>Currency: {currency}</p>
                  <p>Amount: {amount}</p>
                  <p>Description: {description}</p>
                  <input
                    className={styles.input}
                    placeholder="Enter your wallet PIN"
                    type="password"
                    value={walletPin}
                    onChange={(e) => setWalletPin(e.target.value)}
                  />
                  <p className={styles.errorText}>{errorMessage.walletPin}</p>
                  <div onClick={handleConfirmTransfer}><FilledBtn btn={'Confirm & Transfer'} /></div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransferScreen;




