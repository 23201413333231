import React, { createContext, useState, useContext, useMemo, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const userData = localStorage.getItem('user');
    if (userData && userData !== 'undefined') {
      try {
        return JSON.parse(userData);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        localStorage.removeItem('user');
      }
    }
    return null;
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('authToken', userData.token);
    setLoading(false);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('authToken'); 
  };

  const backend_URL = process.env.REACT_APP_BACKEND_BASE_URL;

  const fetchUser = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('authToken'); 
      if (!token) {
        throw new Error('No auth token found');
      }

      const response = await fetch(`${backend_URL}/api/v2/user`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        },
        credentials: 'include',
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Error ${response.status}: ${text}`);
      }

      const data = await response.json();
      setUser(data);
    } catch (error) {
      setError(error.message);
      console.error('fetchUser error:', error); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user && localStorage.getItem('authToken')) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [backend_URL, user]);

  const contextValue = useMemo(() => ({
    user,
    login,
    logout,
    loading,
    error,
  }), [user, loading, error]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
