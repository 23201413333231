import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Resources() {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const navigate = useNavigate();

  const createPin = () => {
    navigate('/pin');
  };

  const accountDetails = () => {
    navigate('/transfer-details');
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, 200);
    setHoverTimeout(timeout);
  };

  return (
    <div
      className="relative inline-block w-full text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <p
          type="text"
          className="inline-flex w-fit cursor-pointer px-4 py-2 text-[16px] font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
        >
          Resources
        </p>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute mt-2 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="py-1 flex w-96 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className='w-[640px]'>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-400 hover:bg-blue-100"
                role="menuitem"
              >
                AWARENESS
              </a>
              <p onClick={createPin}
                className="flex px-4 w-fit py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Blog
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Events
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Experience
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 w-fit font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Customer Stories
              </p>
            </div>
            <div className='w-[640px]'>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-400 hover:bg-blue-100"
                role="menuitem"
              >
                DEVELOPERS
              </a>
              <p onClick={createPin}
                className="flex px-4 py-2 w-fit font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Developer Docs
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Integrations
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                API Reference
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                API Performance
              </p>
            </div>
            <div className='w-[640px]'>
              <a
                onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-400 hover:bg-blue-100"
                role="menuitem"
              >
                SOLUTIONS
              </a>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                E-Commerce
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Real Estate
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Education
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                Freelance
              </p>
              <p onClick={createPin}
                className="flex px-4 py-2 font-bold text-sm text-gray-700 cursor-pointer hover:text-orange-500">
                SaaS
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Resources