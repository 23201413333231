// import Ellipse2 from '../../assets/Ellipse2.png'
// import Ellipse1 from '../../assets/Ellipse1.png'

// export function TransactionHistory() {


//   return (
//       <div className="flex bg-blue-50 h-full px-5 rounded-lg w-full">
//         <div className='flex absolute w-[55.75rem] justify-end'>
//           <img src={Ellipse2} className='flex -mr-8 absolute' alt="" />
//         </div>

//         <div className='flex bg-blue-50 rounded-lg pt-64 '>
//           <img src={Ellipse1} className='flex -ml-5 mt-56 lg:-ml-5 lg:mt-24 absolute' alt='' />
//         </div>
//         <table className="flex mt-7">

//           <thead className='flex px-0 md:flex w-fit flex-col gap-16 items-start'>
//             <tr className='text-gray-500 text-xs font-bold leading-5'>Transactions</tr>
//             <tr className='text-gray-500 text-xs font-medium leading-5'>Sold bags to Irene</tr>
//             <tr className='text-gray-500 text-xs font-medium w-fit leading-5'>Payment received from Irene</tr>
//             <tr className='text-gray-500 text-xs font-medium leading-5'>Sold clothes to Juliet</tr>
//             <tr className='text-gray-500 text-xs font-medium leading-5'>Sold shoes to Eddie</tr>
//             <tr className='text-gray-500 text-xs font-medium leading-5'>Sold bags to Chimi</tr>
//           </thead>

//           <tbody className='flex flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]'>
//               <tr className='text-gray-500 text-xs font-bold leading-5 '>Amount</tr>
//               <tr className='text-gray-600 font-xs font-bold leading-5'>$50</tr>
//               <tr className='text-gray-600 font-xs font-bold leading-5'>$520</tr>
//               <tr className='text-gray-600 font-xs font-bold leading-5'>$150</tr>
//               <tr className='text-gray-600 font-xs font-bold leading-5'>$20</tr>
//               <tr className='text-gray-600 font-xs font-bold leading-5'>$15</tr>
//             </tbody>


//             <tbody className='hidden flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]'>
//               <tr className='text-gray-500 text-xs font-bold leading-5'>Fee</tr>
//               <tr className='text-gray-400 font-xs font-normal'>-$3</tr>
//               <tr className='text-gray-400 font-xs font-normal'>-$3</tr>
//               <tr className='text-gray-400 font-xs font-normal'>-$3</tr>
//               <tr className='text-gray-400 font-xs font-normal'>-$3</tr>
//               <tr className='text-gray-400 font-xs font-normal'>-$3</tr>
//             </tbody>

//             <tbody className='hidden flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]'>
//               <tr className='text-gray-500 text-xs font-bold leading-5'>Status</tr>
//               <tr className='text-yellow-600 font-xs font-medium leading-5'>Pending</tr>
//               <tr className='text-yellow-600 font-xs font-medium leading-5'>Pending</tr>
//               <tr className='text-yellow-600 font-xs font-medium leading-5'>Pending</tr>
//               <tr className='text-yellow-600 font-xs font-medium leading-5'>Pending</tr>
//               <tr className='text-yellow-600 font-xs font-medium leading-5'>Pending</tr>
//             </tbody>

//             <tbody className='flex flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]'>
//             <tr className='text-gray-500 text-xs font-medium leading-5'>Date</tr>
//               <tr className='text-gray-400 font-xs font-medium leading-5'>Feb 21,2021</tr>
//               <tr className='text-gray-400 font-xs font-medium leading-5'>Feb 21,2021</tr>
//               <tr className='text-gray-400 font-xs font-medium leading-5'>Feb 21,2021</tr>
//               <tr className='text-gray-400 font-xs font-medium leading-5'>Feb 21,2021</tr>
//               <tr className='text-gray-400 font-xs font-medium leading-5'>Feb 21,2021</tr>
//             </tbody>
//         </table>
//       </div>


//   )
// }








import React, { useEffect, useState } from 'react';
import Ellipse2 from '../../assets/Ellipse2.png';
import Ellipse1 from '../../assets/Ellipse1.png';
import { CiMenuKebab } from "react-icons/ci";
import { FaFilter } from 'react-icons/fa';

export function TransactionHistory() {
  const [transactions, setTransactions] = useState([]);
  const [menuOpen, setMenuOpen] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('authToken');

  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

  useEffect(() => {
    async function fetchTransactions() {
      try {
        const response = await fetch(`${baseUrl}/api/v2/history`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    }

    if (token) {
      fetchTransactions();
    } else {
      console.error('No authentication token found');
    }
  }, [token]);

  const handleMenuToggle = (index) => {
    setMenuOpen(menuOpen === index ? null : index);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.amount.toString().includes(searchQuery) ||
    transaction.senderAccountNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.recipientAccount_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
    new Date(transaction.date).toLocaleDateString().includes(searchQuery) ||
    transaction.reference.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.verificationCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
    <div className="flex flex-row items-center justify-between gap-4 mb-4">
      <p className='font-bold justify-start'>All transactions</p>
      <div className='flex flex-row gap-4 justify-between'>
        <FaFilter className="mt-3 mr-4 text-gray-500 justify-end" />
        <p className='mt-2'>Filter By: </p>
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search transactions..."
            className="p-2 border rounded w-40%"
          />
        </div>
        </div>
    <div className="flex bg-blue-50 h-full px-5 rounded-lg w-full">
      <div className="flex absolute w-[55.75rem] justify-end">
        <img src={Ellipse2} className="flex -mr-8 absolute" alt="" />
      </div>

      <div className="flex bg-blue-50 rounded-lg pt-64">
        <img src={Ellipse1} className="flex -ml-5 mt-56 lg:-ml-5 lg:mt-24 absolute" alt="" />
      </div>
      <div className="flex flex-col w-full">
        <table className="flex mt-7">
          <thead className="flex px-0 md:flex w-fit flex-col gap-0 items-start">
            <tr className="text-gray-500 text-xs font-bold leading-5">Transactions</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="text-gray-500 text-xs font-medium leading-5">
                {transaction.description}
              </tr>
            ))}
          </thead>

          <tbody className="flex flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]">
            <tr className="text-gray-500 text-xs font-bold leading-5">Amount</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="text-gray-600 font-xs font-bold leading-5">
                {`$${transaction.amount}`}
              </tr>
            ))}
          </tbody>

          <tbody className="hidden flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]">
            <tr className="text-gray-500 text-xs font-bold leading-5">Sender</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="text-gray-400 font-xs font-normal">
                {transaction.senderAccountNumber}
              </tr>
            ))}
          </tbody>

          <tbody className="hidden flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]">
            <tr className="text-gray-500 text-xs font-bold leading-5">Receiver</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="text-gray-400 font-xs font-normal">
                {transaction.recipientAccount_number}
              </tr>
            ))}
          </tbody>

          <tbody className="hidden flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]">
            <tr className="text-gray-500 text-xs font-bold leading-5">Status</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="text-yellow-600 font-xs font-medium leading-5">
                {transaction.status}
              </tr>
            ))}
          </tbody>

          <tbody className="flex flex-col items-center md:flex justify-between gap-4 ml-[40px] lg:ml-[120px]">
            <tr className="text-gray-500 text-xs font-medium leading-5">Date</tr>
            {filteredTransactions.map((transaction, index) => (
              <tr key={index} className="flex items-center">
                <span className="text-gray-400 font-xs font-medium leading-5">
                  {new Date(transaction.date).toLocaleDateString()}
                </span>
                <CiMenuKebab onClick={() => handleMenuToggle(index)} className="cursor-pointer ml-2" />
                {menuOpen === index && (
                  <div className="absolute bg-white shadow-lg rounded-lg p-2 mt-1 z-10">
                    <p className="text-gray-500 text-xs font-medium">Reference: {transaction.reference}</p>
                    <p className="text-gray-500 text-xs font-medium">Verification: {transaction.verificationCode}</p>
                    <p className="text-gray-500 text-xs font-medium">Type: {transaction.type}</p>
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
}




