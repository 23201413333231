import image from '../../assets/imageprofile.png';
import { TbCurrencyNaira } from "react-icons/tb";
import { MdOutlineAttachMoney } from "react-icons/md";
import { HiArrowsUpDown } from "react-icons/hi2";
import { useUser } from '../../userContext/UserContext';
import AccountDetails from '../../Components/Wallets/CreatePin/AccountDetails';
import PinDropdown from '../../Reuseables/Dropdown/PinDropdown';
import { GoChevronRight } from "react-icons/go";

export function Profile() {

    const { user } = useUser();
    if (!user) {
        return <div></div>;
    }

    return (
        <div className="container flex flex-col h-full gap-2 px-4 max-w-full ">
            <div className="flex flex-col w-full py-16 gap-4 rounded-lg">
                <p className='flex flex-row items-center'>settings <GoChevronRight /> profile</p>
                <div className='flex flex-row justify-between'>
                    <p className="font-lato text-3xl capitalize font-medium">{user.username} profile</p>
                    <div><p className="text-gray-500 text-[10px] font-lato">
                        Joined
                    </p>
                    <p className="text-[10px] font-lato">
                        June 22, 2020
                    </p></div>
                </div>
                <div className="flex flex-row justify-center items-center gap-8">
                    <img src={image} className="rounded-full border-2 border-green-500 p-1" />
                    <p className="font-lato text-xl w-fit font-medium">Hello <span className='capitalize text text-2xl'>{user.username}</span> </p>
                </div>
                <button className="text-blue-950 mr-24 w-full font-medium text-[12px] px-5 py-2 bg-blue-200 rounded-2xl">
                    Edit Profile
                </button>
            </div>
            <div className='mt-4'>
                <AccountDetails />
            </div>
            <PinDropdown/>
            <div className="flex flex-col p-4 justify-between h-[380px]">
                <div className="flex justify-start flex-col">
                    <p className="text-black font-medium">Wallet Account</p>
                    <div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Total Balance: </p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><TbCurrencyNaira />
                                <span>1320.00</span>
                            </p>
                        </div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Dollar: </p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><MdOutlineAttachMoney />
                                <span>132.00</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-start flex-col">
                    <p className="text-black font-medium">Escrow Account</p>
                    <div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Total Balance: </p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><TbCurrencyNaira />
                                <span>1320.00</span>
                            </p>
                        </div>
                        <div className="flex justify-between p-2">
                            <p className="flex text-[15px] font-lato text-gray-500">Dollar: </p>
                            <p className="flex text-[15px] font-lato items-center font-medium"><MdOutlineAttachMoney />
                                <span>132.00</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center">
                    <button className="bg-blue-900 text-white py-3 px-16 flex items-center gap-3">
                        <HiArrowsUpDown className="text-white" />
                        Buy Now
                    </button>
                </div>
            </div>

        </div>
    )
}