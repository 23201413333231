import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import LoginNavigation from '../Components/NavigationBar/LoginNavigation'

function LoginLayout() {
  return (
    <>
    <LoginNavigation />
    <Outlet />
    <Footer />
    </>
  )
}

export default LoginLayout