import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function SecuritySettings()  {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const createPin = () => {
    navigate('/pin');
  }

  const accountDetails = () => {
    navigate('/transfer-details');
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="text-left">      
      <div className="flex flex-1">
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a onClick={createPin} className="block px-4 py-2 font-bold text-lg text-gray-700 hover:bg-blue-100" role="menuitem">Create pin</a>
          <a onClick={createPin} className="block px-4 py-2 font-bold text-lg text-gray-700 hover:bg-blue-100" role="menuitem">Update Pin</a>
          <form method="POST" action="#">
            <button onClick={accountDetails} type="submit" className="w-full text-left block px-4 py-2 font-bold text-lg text-gray-700 hover:bg-blue-100" role="menuitem">Reverse Payment</button>
          </form>
        </div>
        <div
          className="inline-block h-[250px] min-h-[1em] w-0.5 self-stretch bg-neutral-100 opacity-100 dark:opacity-50"
        ></div>
      </div>
          
    </div>
  );
};

{/* <a onClick={createPin} className="block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100" role="menuitem">Update Delivery Logistics</a> */}
// export default SecuritySettings;
