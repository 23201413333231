import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/Tranxact on.png";
import { useNavigate } from "react-router-dom";
import style from "./NavigationBar.module.css";
import FilledBtn from "../../Reuseables/Buttons/FilledBtn/FilledBtn";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";
import GhostBtn from "../../Reuseables/Buttons/GhostBtn/GhostBtn";
import clsx from "clsx";
import BankingPlus from "./MenuOptions/BankingPlus";
import Payment from "./MenuOptions/Payment";
import Businesses from "./MenuOptions/Businesses";
import Resources from "./MenuOptions/Resources";
import Pricing from "./MenuOptions/Pricing";
import Support from "./MenuOptions/Support";
import AboutUs from "./MenuOptions/AboutUs";
import SideBankingPlus from "./SideMenu/SideBankingPlus";
import SideMenuIndex from "./SideMenu/SideMenuIndex";
import { FlagIcon } from "react-flag-kit";
import CountryFlag from "./CountryFlag";

const NavigationBar = () => {
  const [isSideMenuOpen, setSideMenu] = useState(false);
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(false);

  const getLogin = () => {
    setLogin(!isLogin);
  };

  const loginLink = [
    {
      label: "Log In",
      link: "login"
    }
  ];


  return (
    <nav className="fixed bg-blue-50 w-screen top-0 z-10">
      <div className="container mx-auto flex justify-between py-0">
        <Link to="/">
          <img src={logo} alt="Tranxactrust logo" width={177} />
        </Link>
        <div className="lg:hidden">
          <FiMenu
            onClick={() => setSideMenu(true)}
            className="text-3xl cursor-pointer absolute top-8 right-4"
          />
        </div>
        <div
          className={clsx(
            "hidden lg:flex ml-28 flex-col lg:flex-row mx-0 justify-center lg:justify-between items-center",
            style.navLinks,
            "lg:gap-4 "
          )}
        >
          <BankingPlus />
          <Payment />
          <Businesses />
          <Resources />
          <Pricing />
          <Support />
        </div>
        <div className="hidden lg:flex flex-col lg:flex-row w-fit mx-0 lg:ml-48 items-center">
          <div className="mr-8"><CountryFlag /></div>
            {loginLink.map((data, index) => (
            <GhostBtn key={index} styler={style.login} btn={data.label} />
          ))}
          <FilledBtn to={"/signup"} style={style.signup} btn={"Sign Up"} />
        </div>
      </div>

      {isSideMenuOpen && (
        <div className="lg:hidden fixed inset-0 bg-white z-50 overflow-y-auto">
          <div className="fixed top-0 left-0 w-full bg-white p-4 z-50 flex justify-between items-center">
            <img src={logo} alt="Tranxactrust logo" className="w-[180px]" />
            <GrClose
              onClick={() => setSideMenu(false)}
              className="text-black text-3xl cursor-pointer"
            />
          </div>
          <SideMenuIndex />
          <div className="flex flex-row justify-evenly items-center mt-4">
           
            <GhostBtn
              styler={style.login}
              btn={"Log In"}
              onClick={getLogin}
            />
            <FilledBtn to={"/signup"} style={style.signup} btn={"Sign Up"} />
          </div>
        </div>
      )}

      {isLogin && (
        <div className={style.login}>
          <div className={style.login}>
            <span className={style.close} onClick={getLogin}>
              &times;
            </span>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;
