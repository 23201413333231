// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       const uploadedUrl = await uploadToCloudinary(imageSrc);

//       const options = {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json',
//           'content-type': 'application/json',
//           'Secret_Key': kyc_SECRET_KEY
//         },
//         body: JSON.stringify({
//           nin: nin,
//           imageUrl: uploadedUrl
//         })
//       };

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log(data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Was Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: uploadedUrl,
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   return (
//     <div className='w-full'>
//       <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//       <h1 className='mt-4 mb-4 flex gap-3 justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//       {step === 1 ? (
//         <>
//           <input
//             type="text"
//             placeholder="Enter your NIN"
//             value={nin}
//             onChange={(e) => setNin(e.target.value)}
//           />
//           <button onClick={handleNextStep} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Next</button>
//           {message && <p>{message}</p>}
//         </>
//       ) : (
//         <>
//           {hasPermission === null ? (
//             <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-green-200 font-medium rounded-[5px]'>Grant Webcam Access</button>
//           ) : hasPermission ? (
//             <>
//               <div className={style.webcamContainer}>
//                 {/* <div className={style.headFrame}>
//                   <div className={style.eyeLeft}></div>
//                   <div className={style.eyeRight}></div>
//                   <div className={style.nose}></div>
//                   <div className={style.mouth}></div>
//                 </div> */}
//                 {imageSrc ? (
//                   <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//                 ) : (
//                   <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className={style.webcamCircle} />
//                 )}
//               </div>
//               <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//               {message && <p className={style.instruction}>{message}</p>}
//               {currentInstructionIndex < instructions.length - 1 ? (
//                 <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//               ) : (
//                 <>
//                   <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                   {imageSrc && (
//                     <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                   )}
//                 </>
//               )}
//             </>
//           ) : (
//             <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//           )}
//           {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//           {isLoading && <p className='text-blue-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner /> Verifying data...</p>}
//         </>
//       )}
//     </div>
//   );
// };

// export default NINWithFace;








// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       const uploadedUrl = await uploadToCloudinary(imageSrc);

//       const requestPayload = {
//         nin: nin,
//         imageUrl: uploadedUrl
//       };

//       const options = {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json',
//           'content-type': 'application/json',
//           'Secret_Key': kyc_SECRET_KEY
//         },
//         body: JSON.stringify(requestPayload)
//       };

//       console.log('Request Payload:', requestPayload);

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log('Response:', data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Was Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: 'uploadedUrl',
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//         console.error('KYC Verification Failed', data);
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   return (
//     <div className='w-full'>
//       <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//       <h1 className='mt-4 mb-4 flex gap-3 justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//       {step === 1 ? (
//         <>
//           <input
//             type="text"
//             placeholder="Enter your NIN"
//             value={nin}
//             onChange={(e) => setNin(e.target.value)}
//           />
//           <button onClick={handleNextStep} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Next</button>
//           {message && <p>{message}</p>}
//         </>
//       ) : (
//         <>
//           {hasPermission === null ? (
//             <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-green-200 font-medium rounded-[5px]'>Grant Webcam Access</button>
//           ) : hasPermission ? (
//             <>
//               <div className={style.webcamContainer}>
//                 <div className={style.headFrame}>
//                   <div className={style.eyeLeft}></div>
//                   <div className={style.eyeRight}></div>
//                   <div className={style.nose}></div>
//                   <div className={style.mouth}></div>
//                 </div>
//                 {imageSrc ? (
//                   <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//                 ) : (
//                   <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className={style.webcamCircle} />
//                 )}
//               </div>
//               <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//               {message && <p className={style.instruction}>{message}</p>}
//               {currentInstructionIndex < instructions.length - 1 ? (
//                 <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//               ) : (
//                 <>
//                   <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                   {imageSrc && (
//                     <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                   )}
//                 </>
//               )}
//             </>
//           ) : (
//             <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//           )}
//           {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//           {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
//         </>
//       )}
//     </div>
//   );
// };

// export default NINWithFace;







// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';
// import Popup from './PopUpNin/PopupNin';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [showPopup, setShowPopup] = useState(true);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       const uploadedUrl = await uploadToCloudinary(imageSrc);

//       const options = {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json',
//           'content-type': 'application/json',
//           'Secret_Key': 'sk_live_nZ0DtwOjVDItj2BzCuwHxNQquUCnEkhutsH9LRvbUZjjvytbgn'
//         },
//         body: JSON.stringify({
//           nin: nin,
//           imageUrl: uploadedUrl
//         })
//       };

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log(data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Was Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: uploadedUrl,
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   return (
//     <div className='flex justify-center items-center flex-col bg-gray-100 min-h-screen'>
//       <div className='flex justify-center items-center flex-col bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
//         <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//         <h1 className='mt-4 mb-4 flex gap-3 w-full justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//         {hasPermission === null ? (
//           <>
//             {/* <input
//               type="text"
//               placeholder="Enter your NIN"
//               value={nin}
//               onChange={(e) => setNin(e.target.value)}
//             />
//             <button onClick={handleNextStep} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Next</button>
//             {message && <p>{message}</p>} */}

//             {/* <Popup nin={nin} setNin={setNin} message={message} /> */}
//             {showPopup && (
//               <Popup
//                 nin={nin}
//                 setNin={setNin}
//                 handleNextStep={handleNextStep}
//                 message={message}
//                 closePopup={closePopup}
//               />
//             )}
//             <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Grant Webcam Access</button>

//           </>
//         ) : hasPermission ? (
//           <>
//             <div className={style.webcamContainer}>
//               <div className={style.headFrame}>
//                 <div className={style.eyeLeft}></div>
//                 <div className={style.eyeRight}></div>
//                 <div className={style.nose}></div>
//                 <div className={style.mouth}></div>
//               </div>
//               {imageSrc ? (
//                 <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//               ) : (
//                 <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className={style.webcamCircle} />
//               )}
//             </div>
//             <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//             {message && <p className={style.instruction}>{message}</p>}
//             {currentInstructionIndex < instructions.length - 1 ? (
//               <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//             ) : (
//               <>
//                 <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                 {imageSrc && (
//                   <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                 )}
//               </>
//             )}
//           </>
//         ) : (
//           <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//         )}
//         {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//         {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
//       </div>
//     </div>
//   );
// };

// export default NINWithFace;









// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';
// import Popup from '../NINWithFace/PopUpNin/PopupNin';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const [showPopup, setShowPopup] = useState(true);
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//   const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user"
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//       console.log('Captured Image:', imageSrc);
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   console.log('Cloudinary API Key:', cloudinaryAPI_KEY);
//   console.log('KYC Secret Key:', kyc_SECRET_KEY);
//   console.log("YOur NIN is >> ", nin)

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     console.log('Form Data:', formData);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     console.log('Cloudinary Response:', data);

//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       console.log('Submitting for verification...');
//       const uploadedUrl = await uploadToCloudinary(imageSrc);
//       console.log('Uploaded Image URL:', uploadedUrl);

//       const requestPayload = {
//         nin: nin,
//         imageUrl: uploadedUrl
//       };

//       const options = {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//           'Secret_Key': kyc_SECRET_KEY
//         },
//         body: JSON.stringify(requestPayload)
//       };

//       console.log('Request Payload:', requestPayload);

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log('KYC Response:', data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: uploadedUrl,
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//         console.error('KYC Verification Failed', data);
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//       setShowPopup(false);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   const closePopup = () => {
//     setShowPopup(false);
//   };

//   return (
//     <div className='w-full'>
//       {showPopup && (
//         <Popup
//           nin={nin}
//           setNin={setNin}
//           handleNextStep={handleNextStep}
//           message={message}
//           closePopup={closePopup}
//         />
//       )}
//       <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//       <h1 className='mt-4 mb-4 flex gap-3 justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//       {step === 2 && (
//         <>
//           {hasPermission === null ? (
//             <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-green-200 font-medium rounded-[5px]'>Grant Webcam Access</button>
//           ) : hasPermission ? (
//             <>
//               <div className={style.webcamContainer}>
//                 <div className={style.headFrame}>
//                   <div className={style.eyeLeft}></div>
//                   <div className={style.eyeRight}></div>
//                   <div className={style.nose}></div>
//                   <div className={style.mouth}></div>
//                 </div>
//                 {imageSrc ? (
//                   <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//                 ) : (
//                   <Webcam
//                     audio={false}
//                     ref={webcamRef}
//                     screenshotFormat="image/jpeg"
//                     videoConstraints={videoConstraints}
//                     className={style.webcamCircle}
//                   />
//                 )}
//               </div>
//               <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//               {message && <p className={style.instruction}>{message}</p>}
//               {currentInstructionIndex < instructions.length - 1 ? (
//                 <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//               ) : (
//                 <>
//                   <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                   {imageSrc && (
//                     <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                   )}
//                 </>
//               )}
//             </>
//           ) : (
//             <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//           )}
//           {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//           {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
//         </>
//       )}
//     </div>
//   );
// };

// export default NINWithFace;







import React, { useState, useCallback, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import style from "./KYC.module.css";
import logo from "../../images/Tranxact on.png";
import Spinner from '../../pages/Signup/Spinner/Spinner';
import Popup from '../NINWithFace/PopUpNin/PopupNin';

const NINWithFace = ({ onVerificationStatusChange }) => {
  const [nin, setNin] = useState('');
  const [message, setMessage] = useState('');
  const [kycMessage, setKycMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [step, setStep] = useState(1);
  const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
  const [imageSrc, setImageSrc] = useState('');
  const [showPopup, setShowPopup] = useState(true);
  const webcamRef = useRef(null);

  const instructions = [
    'Please face the camera directly.',
    'Turn your face to the right.',
    'Turn your face to the left.',
    'Look up at the camera.',
    'Look down at the camera.',
    'Smile for the camera.'
  ];

  useEffect(() => {
    if (hasPermission) {
      setMessage(instructions[currentInstructionIndex]);
    }
  }, [hasPermission, currentInstructionIndex]);

  const requestWebcamPermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setHasPermission(true);
    } catch (error) {
      setHasPermission(false);
    }
  };

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImageSrc(imageSrc);
      setMessage('Image captured. Click "Submit" to proceed.');
      console.log('Captured Image:', imageSrc);
    }
  }, []);

  const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
  const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
  const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
  const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
  const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
  const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

  console.log('Cloudinary API Key:', cloudinaryAPI_KEY);
  console.log('KYC Secret Key:', kyc_SECRET_KEY);
  console.log("Your NIN is >>>> ", nin)
 

  const uploadToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'tranxactrust');
    formData.append('api_key', cloudinaryAPI_KEY);
    formData.append('timestamp', Math.floor(Date.now() / 1000));
    formData.append('api_secret', cloudinarySECRET_KEY);

    console.log('Form Data:', formData);

    const response = await fetch(cloudinary_base_url, {
      method: 'POST',
      body: formData
    });

    const data = await response.json();
    console.log('Cloudinary Response:', data);

    return data.secure_url;
  };

  const handleNextInstruction = () => {
    if (currentInstructionIndex < instructions.length - 1) {
      setCurrentInstructionIndex(currentInstructionIndex + 1);
    } else {
      capture();
    }
  };

  const submitForVerification = async () => {
    if (!imageSrc || !nin) {
      setMessage('Please capture an image and enter your NIN');
      return;
    }
    setIsLoading(true);
    setMessage('');

    try {
      console.log('Submitting for verification...');
      const uploadedUrl = await uploadToCloudinary(imageSrc);
      console.log('Uploaded Image URL:', uploadedUrl);

      const requestPayload = {
        nin: nin,
        imageUrl: uploadedUrl
      };

      const options = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Secret_Key': kyc_SECRET_KEY
        },
        body: JSON.stringify(requestPayload)
      };

      console.log('Request Payload:', requestPayload);

      const response = await fetch(kyc_base_url, options);
      const data = await response.json();
      console.log('KYC Response:', data);

      if (response.ok) {
        setKycMessage("KYC Verification Successful");
        onVerificationStatusChange('Success'); 
        const backendPayload = {
          nin: nin,
          imageUrl: uploadedUrl,
        };

        await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(backendPayload)
        });
      } else {
        setKycMessage("KYC Verification Failed");
        onVerificationStatusChange('Failed'); 
        console.error('KYC Verification Failed', data);
      }
    } catch (error) {
      console.error('Error during KYC verification:', error);
      setMessage('An error occurred during KYC verification');
      setKycMessage("KYC Verification Failed");
      onVerificationStatusChange('Failed'); 
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextStep = () => {
    if (nin) {
      setStep(2);
      setShowPopup(false);
    } else {
      setMessage('Please enter your NIN');
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };
  
 

  return (
    <div className='w-full'>
      {showPopup && (
        <Popup
          nin={nin}
          setNin={setNin}
          handleNextStep={handleNextStep}
          message={message}
          closePopup={closePopup}
        />
      )}
      <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
      <h1 className='mt-4 mb-4 flex gap-3 justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
      {step === 2 && (
        <>
          {hasPermission === null ? (
            <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-green-200 font-medium rounded-[5px]'>Grant Webcam Access</button>
          ) : hasPermission ? (
            <>
              <div className={style.webcamContainer}>
                <div className={style.headFrame}>
                  <div className={style.eyeLeft}></div>
                  <div className={style.eyeRight}></div>
                  <div className={style.nose}></div>
                  <div className={style.mouth}></div>
                </div>
                {imageSrc ? (
                  <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
                ) : (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className={style.webcamCircle}
                  />
                )}
              </div>
              <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
              {message && <p className={style.instruction}>{message}</p>}
              {currentInstructionIndex < instructions.length - 1 ? (
                <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
              ) : (
                <>
                  <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
                  {imageSrc && (
                    <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
                  )}
                </>
              )}
            </>
          ) : (
            <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
          )}
          {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
          {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
        </>
      )}
    </div>
  );
};

export default NINWithFace;








// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//     const videoConstraints = {
//     width: 1280,
//     height: 720,
//     facingMode: "user"
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       const uploadedUrl = await uploadToCloudinary(imageSrc);

//       const options = {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json',
//           'content-type': 'application/json',
//           'Secret_Key': kyc_SECRET_KEY
//         },
//         body: JSON.stringify({
//           nin: nin,
//           imageUrl: uploadedUrl
//         })
//       };

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log(data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Was Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: uploadedUrl,
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   return (
//     <div className='flex justify-center items-center flex-col bg-gray-100 min-h-screen'>
//       <div className='flex justify-center items-center flex-col bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
//         <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//         <h1 className='mt-4 mb-4 flex gap-3 w-full justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//         {hasPermission === null ? (
//           <>
//             <button onClick={requestWebcamPermission} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Request Webcam Access</button>
//             {/* <input
//               type="text"
//               placeholder="Enter your NIN"
//               value={nin}
//               onChange={(e) => setNin(e.target.value)}
//             />
//             <button onClick={handleNextStep} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Next</button>
//             {message && <p>{message}</p>} */}
//           </>
//         ) : hasPermission ? (
//           <>
//             <div className={style.webcamContainer}>
//               <div className={style.headFrame}>
//                 <div className={style.eyeLeft}></div>
//                 <div className={style.eyeRight}></div>
//                 <div className={style.nose}></div>
//                 <div className={style.mouth}></div>
//               </div>
//               {imageSrc ? (
//                   <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//                 ) : (
//                   <Webcam
//                   audio={false}
//                   ref={webcamRef}
//                   screenshotFormat="image/jpeg"
//                   videoConstraints={videoConstraints}
//                   className={style.webcamCircle}
//                 />
//                 )}
//             </div>
//               <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//               {message && <p className={style.instruction}>{message}</p>}
//               {currentInstructionIndex < instructions.length - 1 ? (
//                 <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//               ) : (
//                 <>
//                   <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                   {imageSrc && (
//                     <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                   )}
//                 </>
//               )}
//           </>
//           ) : (
//             <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//           )}
//           {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//           {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
      
//       </div>
//     </div>
//   );
// };


// export default NINWithFace;




// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import style from "./KYC.module.css";
// import logo from "../../images/Tranxact on.png";
// import Spinner from '../../pages/Signup/Spinner/Spinner';

// const NINWithFace = () => {
//   const [nin, setNin] = useState('');
//   const [message, setMessage] = useState('');
//   const [kycMessage, setKycMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [hasPermission, setHasPermission] = useState(null);
//   const [step, setStep] = useState(1);
//   const [currentInstructionIndex, setCurrentInstructionIndex] = useState(0);
//   const [imageSrc, setImageSrc] = useState('');
//   const webcamRef = useRef(null);

//   const instructions = [
//     'Please face the camera directly.',
//     'Turn your face to the right.',
//     'Turn your face to the left.',
//     'Look up at the camera.',
//     'Look down at the camera.',
//     'Smile for the camera.'
//   ];

//   useEffect(() => {
//     if (hasPermission) {
//       setMessage(instructions[currentInstructionIndex]);
//     }
//   }, [hasPermission, currentInstructionIndex]);

//   const requestWebcamPermission = async () => {
//     try {
//       await navigator.mediaDevices.getUserMedia({ video: true });
//       setHasPermission(true);
//     } catch (error) {
//       setHasPermission(false);
//     }
//   };

//   const capture = useCallback(() => {
//     if (webcamRef.current) {
//       const imageSrc = webcamRef.current.getScreenshot();
//       setImageSrc(imageSrc);
//       setMessage('Image captured. Click "Submit" to proceed.');
//     }
//   }, []);

//   const cloudinaryAPI_KEY = process.env.REACT_APP_API_CLOUDINARY_API_KEY;
//   const cloudinarySECRET_KEY = process.env.REACT_APP_API_CLOUDINARY_SECRET_KEY;
//   const kyc_SECRET_KEY = process.env.REACT_APP_KYC_SECRET_KEY;
//   const cloudinary_base_url = process.env.REACT_APP_API_CLOUDINARY_BASE_URL;
//   const kyc_base_url = process.env.REACT_APP_API_KYC_BASE_URL;
//   const backend_base_url = process.env.REACT_APP_BACKEND_BASE_URL;

//   const uploadToCloudinary = async (image) => {
//     const formData = new FormData();
//     formData.append('file', image);
//     formData.append('upload_preset', 'tranxactrust');
//     formData.append('api_key', cloudinaryAPI_KEY);
//     formData.append('timestamp', Math.floor(Date.now() / 1000));
//     formData.append('api_secret', cloudinarySECRET_KEY);

//     const response = await fetch(cloudinary_base_url, {
//       method: 'POST',
//       body: formData
//     });

//     const data = await response.json();
//     return data.secure_url;
//   };

//   const handleNextInstruction = () => {
//     if (currentInstructionIndex < instructions.length - 1) {
//       setCurrentInstructionIndex(currentInstructionIndex + 1);
//     } else {
//       capture();
//     }
//   };

//   const submitForVerification = async () => {
//     if (!imageSrc || !nin) {
//       setMessage('Please capture an image and enter your NIN');
//       return;
//     }
//     setIsLoading(true);
//     setMessage('');

//     try {
//       const uploadedUrl = await uploadToCloudinary(imageSrc);

//       const options = {
//         method: 'POST',
//         headers: {
//           'accept': 'application/json',
//           'content-type': 'application/json',
//           'Secret_Key': kyc_SECRET_KEY
//         },
//         body: JSON.stringify({
//           nin: nin,
//           imageUrl: uploadedUrl
//         })
//       };

//       const response = await fetch(kyc_base_url, options);
//       const data = await response.json();
//       console.log(data);

//       if (response.ok) {
//         setKycMessage("KYC Verification Was Successful");

//         const backendPayload = {
//           nin: nin,
//           imageUrl: uploadedUrl,
//         };

//         await fetch(`${backend_base_url}/api/v2/accountSetUp`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(backendPayload)
//         });
//       } else {
//         setKycMessage("KYC Verification Failed");
//       }
//     } catch (error) {
//       console.error('Error during KYC verification:', error);
//       setMessage('An error occurred during KYC verification');
//       setKycMessage("KYC Verification Failed");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleNextStep = () => {
//     if (nin) {
//       setStep(2);
//     } else {
//       setMessage('Please enter your NIN');
//     }
//   };

//   return (
//     <div className='flex justify-center items-center flex-col bg-gray-100 min-h-screen'>
//       <div className='flex justify-center items-center flex-col bg-white shadow-lg rounded-lg p-8 w-full max-w-md'>
//         <img src={logo} alt='Tranxactrust logo' className='w-[250px] m-auto' />
//         <h1 className='mt-4 mb-4 flex gap-3 w-full justify-center px-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>KYC Verification</h1>
//         {hasPermission === null ? (
//           <>
//             <input
//               type="text"
//               placeholder="Enter your NIN"
//               value={nin}
//               onChange={(e) => setNin(e.target.value)}
//             />
//             <button onClick={handleNextStep} className='flex gap-3 items-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-[5px]'>Next</button>
//             {message && <p>{message}</p>}
//             <button onClick={requestWebcamPermission} className='flex gap-3 m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Grant Webcam Access</button>
//           </>
//         ) : hasPermission ? (
//           <>
//             <div className={style.webcamContainer}>
//               <div className={style.headFrame}>
//                 <div className={style.eyeLeft}></div>
//                 <div className={style.eyeRight}></div>
//                 <div className={style.nose}></div>
//                 <div className={style.mouth}></div>
//               </div>
//               {imageSrc ? (
//                   <img src={imageSrc} alt="Captured" className={style.webcamCircle} />
//                 ) : (
//                   <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className={style.webcamCircle} />
//                 )}
//             </div>
//               <span className='flex gap-3 w-fit m-auto items-center px-4 mt-4 py-2 text-blue-500 bg-yellow-500 font-medium rounded-[5px]'>(ATTENTION: Please ensure you are in a well-lit environment)</span>
//               {message && <p className={style.instruction}>{message}</p>}
//               {currentInstructionIndex < instructions.length - 1 ? (
//                 <button onClick={handleNextInstruction} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Continue</button>
//               ) : (
//                 <>
//                   <button onClick={capture} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Capture Photo</button>
//                   {imageSrc && (
//                     <button onClick={submitForVerification} className='flex gap-3 items-center px-4 mt-4 m-auto py-2 text-blue-500 bg-blue-200 font-medium rounded-[5px]'>Submit</button>
//                   )}
//                 </>
//               )}
//           </>
//           ) : (
//             <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>Permission denied. Please allow access to your webcam to proceed.</p>
//           )}
//           {kycMessage && <p className='text-red-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-2 font-medium rounded-[5px]'>{kycMessage}</p>}
//           {isLoading && <p className='text-green-500 mt-4 mb-4 flex gap-3 justify-center px-4 py-4 font-medium rounded-[5px]'><Spinner />Verifying data...</p>}
      
//       </div>
//     </div>
//   );
// };


// export default NINWithFace;


