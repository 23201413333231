import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import WithdrawalSuccessScreen from './TransferSuccessScreen';
import styles from './TransferScreen.module.css';
import logo from '../../../images/Tranxact on.png';
import FilledBtn from '../../../Reuseables/Buttons/FilledBtn/FilledBtn';
import SelectBank from './SelectBank';

const ReversePayment = () => {
  const navigate = useNavigate();
  const [senderUsername, setSenderUsername] = useState('');
  const [withdrawalStatus, setWithdrawalStatus] = useState(null);
  const [accountNumber, setAccountNumber] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [accountName, setAccountName] = useState('........................');
  const [currency, setCurrency] = useState('Nigerian Naira');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [walletPin, setWalletPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTransferDisabled, setIsTransferDisabled] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [reason, setReason] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(null);

  useEffect(() => {
    const fetchSenderUsername = async () => {
      try {
        const username = await localStorage.getItem('senderUsername');
        setSenderUsername(username);
      } catch (error) {
        console.error('Error retrieving sender username:', error);
      }
    };

    fetchSenderUsername();
  }, []);

  const handleNextStep = () => {
    if (currentStep === 1 && !reason) {
      setErrorMessage('Please provide a reason for reversing the payment.');
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handleConfirmReverse = async () => {
    setIsLoading(true);
    const userAccountNumber = await localStorage.getItem('userAccountNumber');

    const reverseData = {
      verificationCode,
      businessName: transactionDetails.businessName,
      reason,
      businessAccountNumber: transactionDetails.businessAccountNumber,
      user: {
        accountNumber: userAccountNumber,
        amount: transactionDetails.amount,
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/reverse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reverseData),
      });
      const result = await response.json();
      if (response.ok) {
        setWithdrawalStatus('success');
        navigate('/transfer-success');
      } else {
        setError(true);
        setErrorMessage(result.message || 'Transaction failed');
      }
    } catch (error) {
      console.error('Error reversing payment:', error);
      setError(true);
      setErrorMessage('Transaction failed');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyAndRetrieveDetails = async () => {
    setIsLoading(true);
    const userAccountNumber = await localStorage.getItem('userAccountNumber');

    const verifyData = {
      verificationCode,
      businessName: '',
      reason,
      businessAccountNumber: '',
      user: {
        accountNumber: userAccountNumber,
      },
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/reverse`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(verifyData),
      });
      const result = await response.json();
      if (response.ok) {
        setTransactionDetails(result);
        setShowConfirmation(true);
      } else {
        setErrorMessage(result.message || 'Invalid verification code.');
      }
    } catch (error) {
      console.error('Failed to verify code:', error);
      setErrorMessage('Failed to verify code.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='w-full h-screen m-auto flex justify-center items-center bg-sign-up bg-cover bg-center'>
      {error ? (
        <ErrorPage message={errorMessage} />
      ) : (
        <div className={styles.container}>
          <img src={logo} alt='Tranxactrust logo' className='w-[220px]' />

          <div className={styles.header}>
            <p className={styles.headerText}>Reverse Payment</p>
          </div>
          {withdrawalStatus && (
            <WithdrawalSuccessScreen status={withdrawalStatus} onPressOk={() => navigate('/dashboard')} />
          )}
          {!withdrawalStatus && (
            <div className={styles.inputContainer}>
              {currentStep === 1 && (
                <>
                  <textarea
                    className={styles.input}
                    placeholder="Enter reason for reversing payment"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    required
                  />
                  <p className={styles.errorText}>{errorMessage}</p>
                  <div onClick={handleNextStep}><FilledBtn btn={'Next'} /></div>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <input
                    className={styles.input}
                    placeholder="Enter verification code"
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                  <p className={styles.errorText}>{errorMessage}</p>
                  <div onClick={handleVerifyAndRetrieveDetails}><FilledBtn btn={'Verify Code'} /></div>
                </>
              )}

              {showConfirmation && transactionDetails && (
                <div className={styles.confirmationPopup}>
                  <h3>Transaction Details</h3>
                  <p>Business Name: {transactionDetails.businessName}</p>
                  <p>Account Number: {transactionDetails.businessAccountNumber}</p>
                  <p>Amount: {transactionDetails.amount}</p>
                  <div onClick={handleConfirmReverse}><FilledBtn btn={'Confirm Reverse Payment'} /></div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReversePayment;
