import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import card from "../images/Credit_Card.png"
import IntroSect from "../Reuseables/introsection/IntroSect";
import banner from "../images/banner-payment-links.svg";
import bg from "../images/bg.png";


const Trustmonie = () => {
    return (
        <IntroSect text={"Sell and cashout,"} imgage={banner} size={{display: "block",
            width: "100%"}} alt={"banner-payment-links"} text2={" enjoy seamless delivery"} 
        text3={"Package and send the product to the delivery agent. Enjoy instant cashout while customers expect their product. Pay vendors from anywhere and retain control over payouts."} btn={"Sign Up Now"} btn2={"Contact sales"}/>
        
    );
}

export default Trustmonie;