import React from "react";
import { Link, useNavigate } from "react-router-dom";
import photo from "../../../images/payment-photo.png";
import style from "./Products.module.css";
import reverseImage from "../../../images/reversal-transform.png";
import reverse from "../../../images/tranxactcard.png";
import property from "../../../images/property.png";
import shopKeeper from "../../../images/Store Keeper.png";


const Products = () => {
    const navigate = useNavigate();

    const getProducts = () => {
        navigate('/signup');
    };

    const openShop = () => {
        navigate('/shop');
    };

    const goInvest = () => {
        navigate('/investrust')
    }

    return (
        <section className="container mt-4 mx-auto h-full w-screen flex flex-col">
            <div className={style.barrier1}>
            <p className='mt-4 lg:text-5xl text-center '>SELL ONLINE WITH 
                <span className='lg:text-5xl text-[#0887ef]'> DUAL ACCOUNTS </span> </p>

                <p className={style.trust2}>Regardless of the size of your business,
                    our payment tools are designed to keep it scaling.
                </p>
            </div>


            <div className={style.paymentIntro}>
                <div className={style.paycolumn}>
                    Trustmonie

                    <p className={style.monie1}>Sell more and cashout 
                        with Trustmonie while we take care of delivery through our 
                        vetted logistics partners. Get dual accounts in one 
                        <Link to={'/signup'} className={style.update} > Sign Up </Link> 
                        and turn strangers to regular customers.</p>

                    <a href="#top"><Link to={'/trustmonie'} className={style.moreContainer} >Sign Up Now
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#0887ef" />
                            <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#0887ef" />
                        </svg>
                    </Link></a>

                    <img className={style.pay} src={photo} alt="payment image" />

                    Shop

                    <p className={style.monie1}>Stay ever closer to thousands of shoppers paying on
                        Tranxactrust. Open your one-stop online Shop on our marketplace
                        and start selling to new customers globally.
                    </p>
                    <img src={shopKeeper} className={style.store} alt="Store Keeper" />

                    <Link to={'/store'} className={style.moreContainer} > Go Shopping

                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#0887ef" />
                            <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#0887ef" />
                        </svg>
                    </Link>
                </div>

                {/* <span className={style.monie}>TrxtPay</span>

                    <p className={style.monie1}>Stay ahead of others and start accepting crypto payment.
                        Convert fiat to Ethereum, Bitcoin and USDT. TrxtPay enables gasless
                        transactions for merchant and individuals.You also get direct and
                        escrow crypto wallets in one <Link to={'/trxtpay'} className="update" > Sign Up. </Link>

                    </p>

                    <Link to={'/trxtpay'} className={style.moreContainer} > Sign Up Now

                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#0887ef" />
                            <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#0887ef" />
                        </svg>
                    </Link> */}

                {/* </div> */}

                <div className={style.paycolumn}>
                    Investrust

                    <p className={style.monie1}>Investrust is a powerful tool designed 
                        for local and cross-border real estate payment. Accept payment 
                        in multiple currencies. Guarantee your clients payment protection 
                        and reversal.
                    </p>

                    {/* <img className={style.pay} src={house} alt="house" /> */}
                    <img className="h-64" src={property} alt="house" />

                    <button onClick={goInvest} className={style.account2}>Start Investing
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#FCFCFD" />
                            <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#FCFCFD" />
                        </svg>
                    </button>

                    <div className={style.unlock}>
                    <p>Unlock payment barriers
                    <span className={style.span1}> for your business</span></p>
                        {/* <p> Unlock unlimited
                            possibilities.
                        </p> */}

                        <div className="mt-12">
                            <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Convert first-time customers to regulars customers</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Access payments anytime</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Builds customer trust on your brand</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Avoid payment reversal.</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>100% fast, easy-to-use, safe and secure</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Enjoy instant withdrawal.</p>
                        </div>

                        {/* <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Fastest way to increased customer conversion</p>
                        </div>

                        <div className={style.ticker}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="10" fill="#EEF4FF" />
                                <path d="M8.79995 15.9L4.59995 11.7L3.19995 13.1L8.79995 18.7L20.8 6.70005L19.4 5.30005L8.79995 15.9Z" fill="#0887ef" />
                            </svg>
                            <p className={style.puncher}>Build trust with strangers for the long-term business relationship</p>
                        </div> */}

                        <button onClick={getProducts} className={style.account2}>Learn More
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.34788 8.75L7.29163 10.8062L13.9708 17.5L7.29163 24.1938L9.34788 26.25L18.0979 17.5L9.34788 8.75Z" fill="#FCFCFD" />
                                <path d="M18.9583 8.75L16.902 10.8062L23.5812 17.5L16.902 24.1938L18.9583 26.25L27.7083 17.5L18.9583 8.75Z" fill="#FCFCFD" />
                            </svg>
                        </button>
                        </div>
                    </div>

                    <img src={reverse} alt="Reverse payment card" />

                </div>


            </div>


        </section>
    );
}

export default Products