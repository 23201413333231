import React from "react";


const Success = () => {
    return(
        <div className="bg-green ">
            Payment successful!
        </div>
    )
}

export default Success;