import React from 'react';
import tranxact from '../../images/Tranxact on.png';
import { Link, useNavigate } from 'react-router-dom';
import rightIcon from '../../images/Ellipse 4.png';
import lock from '../../images/Group 10@2x.png';
import vector from '../../images/Vector.png';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleResetLink = () => {
    navigate('/reset-password');
  };

  return (
    <main className="flex-1  bg-cover bg-center pt-[56px] pb-[56px] ">
    <div className="flex flex-col md:flex-row h-auto md:h-[32rem] w-full max-w-[82rem] mx-auto px-4 sm:px-8 md:px-32">
    
      <section className=" flex flex-col justify-center items-center bg-white p-10 shadow-lg ">
        <div className="">
          <Link to={'/'}>
            <img className=" h-10 md:h-20 mb-4 " src={tranxact} alt="Tranxact Logo" />
          </Link>
          <div className="">
          <h1 className=" text-xl font-semibold md:text-xl  mb-4  md:text-left">Forgot Password</h1>
        <p className="text-gray-600 mb-6  md:text-left">
           Enter your email to receive a password reset link.
        </p>
          </div>
           <form className=" space-y-1 w-[70%] ">
            <div>
              <label className="block text-gray-700 mb-3">Email</label>
              <input
                type="email"
                placeholder="johnDoe@email.com"
                className="w-full p-3 bg-zinc-300 rounded mb-4 h-10"
              />
            </div>
            <button 
             className="w-full bg-[#0d47a1] text-white py-2 rounded h-10 "
             type="button"
            onClick={handleResetLink}
          >
             Send Reset Link
           </button>
            
          </form>
          <p className="mt-4 text-center md:text-left">
           Remember your password?{' '}
           <Link to="/login" className="text-[#0d47a1] hover:underline font-bold gap-3">
             Log in
           </Link>
         </p>
        </div>
      </section>
  
      
      <section className="hidden md:flex flex-col justify-center items-center bg-[#0d47a1] p-10  ">
        <img src={rightIcon} alt="Right Icon" className="" />
        <img src={lock} alt="Lock Icon" className="" />
          
           <div className="text-center text-white relative mb-[115%] py-3 " > 
           
            <div className="">
            <p className="font-semibold mb-4 pr-48 text-sm" >Trouble accessing your account?</p>
             <p className="text-sm mb-6 ">
               Don't worry! We'll help you get back on track. 
              Simply enter your registered email, 
              and we'll send you a link to reset your password.
             </p>
            </div>
            
            <div className="flex justify-around gap-2 mt-5">
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Quick and Secure" />
                 <p className="text-sm">Quick and Secure</p>
               </div>
  
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Data Safety" />
                 <p className="text-sm">Data Safety</p>
               </div>
  
              <div className="flex items-center gap-2">
                 <img src={vector} className="w-4" alt="24/7 Support" />
                 <p className="text-sm">24/7 Support</p>
               </div>
  
            </div>
        </div> 
      </section>
  
    </div>
  </main>
    
  
  
  
  );
};

export default ForgotPassword;
