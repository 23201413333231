import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import tranxact from "../../images/Tranxact on.png";
import { useUser } from "../../userContext/UserContext";
import Spinner from "../Signup/Spinner/Spinner";
import rightIcon from '../../images/Ellipse 4.png';
import group9 from '../../images/Group 9.png';
import vector from '../../images/Vector.png';

const Login = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const { login } = useUser();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log("A login click happened");

        const backend_URL = process.env.REACT_APP_BACKEND_BASE_URL;
        if (!backend_URL) {
            setError('Backend URL is not defined. Please check your environment variables.');
            setIsLoading(false);

            return;
        }

        try {
            const response = await fetch(`${backend_URL}/api/v2/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });

            if (response.ok) {
                const data = await response.json();
                if (data.access_token) {
                    localStorage.setItem('authToken', data.access_token);
                    console.log(data.access_token);
                    const user = {
                        username,
                        token: data.access_token,
                    };
                    login(user);
                    setUsername('');
                    setPassword('');
                    setError('');
                    navigate('/home');
                } else {
                    setError('Login response does not contain an access token');
                    console.error('Login response does not contain an access token', data);
                }
            } else {
                const errorText = await response.text();
                try {
                    const errorData = JSON.parse(errorText);
                    setError(errorData.message);
                    console.error('Authentication failed:', errorData.message);
                } catch (jsonParseError) {
                    setError(`Authentication failed: ${errorText}`);
                    console.error('Authentication failed:', errorText);
                }
            }
        } catch (error) {
            setError("Check internet connection");
            console.error('Login error: ', error);
        }finally {
            setIsLoading(false);
        }
    };

    return (
      <main className="flex-1  bg-cover bg-center pt-[64px] pb-[64px] ">
      <div className="flex flex-col md:flex-row h-auto md:h-[32rem] w-full max-w-[82rem] mx-auto px-4 sm:px-8 md:px-32">
      
        <section className=" flex flex-col justify-center items-center bg-white p-10 shadow-lg ">
          <div className="">
            <Link to={'/'}>
              <img className=" h-10 md:h-20 mb-4 " src={tranxact} alt="Tranxact Logo" />
            </Link>
            <div className="">
            <h1 className="text-xl font-semibold mb-5 ">Log in to your account</h1>
            <p className="text-gray-600 md:mb-2 ">Secure your transactions with Tranxactrust.</p>
            </div>
             <form className=" space-y-1 w-[95%] ">
              <div>
                <label className="block text-gray-700 mb-3">Email</label>
                <input
                  type="email"
                  placeholder="johnDoe@email.com"
                  className="w-full p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                />
              </div>
    
              <div className="flex justify-between items-center gap-4 ">
                <label className="block text-gray-700">Password</label>
                <Link to={'/forgot-password'} className="text-sm text-[#0d47a1] hover:underline">
                  Forgot your password?
                </Link>
              </div>
              
              <input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="••••••••••"
                className="w-full p-3 border border-gray-300 rounded mb-4 h-10 bg-zinc-300"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
                
              
               <div className="flex items-center gap-2 mb-6">
                <input 
                  type="checkbox" 
                  className="w-5 text-[#0d47a1] h-5"
                  onChange={togglePasswordVisibility}
                  checked={passwordVisible}
                />
                <p className="mb-3">Remember Me</p>
              </div>
              
               {error && <p className=" bg-red-700 mt-2 text-white">{error}</p>}
              <button 
                className="w-full bg-[#0d47a1] text-white py-1 rounded flex justify-center items-center hover:bg-[#003c8f] h-10"
                type="button"
                onClick={handleLogin} 
                disabled={isLoading}
              >
                 {isLoading ? <Spinner /> : <h2>Log In</h2>}
              </button>
              
            </form>
            <div className="mt-4 ">
              <p>New to Tranxactrust? 
                <Link to={'/signup'} className="text-[#0d47a1] ml-1 font-semibold hover:underline ">
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </section>
    
        <section className="hidden md:flex flex-col justify-center items-center bg-[#0d47a1] p-10  ">
        <img src={rightIcon} alt="Right Icon" className="" />
        <img src={group9} alt="Lock Icon" className="" />
          
           <div className="text-center text-white relative mb-[115%] py-3 " > 
           
            <div className="">
            <p className="text-sm font-semibold mb-4 pr-48 ">Secure. Simple. Reliable.</p>
            <p className="mr-2" >At Tranxactrust, we provide a safe environment for your
                                 transactions, ensuring peace of mind with every deal.
                                 Experience the trust and transparency you deserve.</p>
            </div>
            
            <div className="flex justify-around gap-2 mt-5">
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Quick and Secure" />
                 <p className="text-sm">Quick and Secure</p>
               </div>
  
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Data Safety" />
                <p className="text-sm">Real-Time Updates</p> 
               </div>
  
              <div className="flex items-center gap-2">
                 <img src={vector} className="w-4" alt="24/7 Support" />
                 <p className="text-sm">24/7 Support</p>
               </div>
  
            </div>
        </div> 
      </section>    
      </div>
    </main>
      
    );
};

export default Login;

