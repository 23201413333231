
import React from 'react';
import tranxact from '../../images/Tranxact on.png';
import { Link } from 'react-router-dom';
import rightIcon from '../../images/Ellipse 4.png';
import lock from '../../images/Group 11.png';
import vector from '../../images/Vector.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const ResetPassword = () => {
 
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Navigate hook for redirection

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validate if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
    } else {
      setError('');
      // Proceed with the reset password process here (e.g., API call)

      // Simulate successful password reset and navigate to login
      navigate('/login');
    }
  };
    return (
      <main className="flex-1  bg-cover bg-center pt-[56px] pb-[56px] ">
    <div className="flex flex-col md:flex-row h-auto md:h-[32rem] w-full max-w-[82rem] mx-auto px-4 sm:px-8 md:px-32">
    
      <section className=" flex flex-col justify-center items-center bg-white p-10 shadow-lg ">
        <div className="">
          <Link to={'/'}>
            <img className=" h-10  md:h-20 mb-4 " src={tranxact} alt="Tranxact Logo" />
          </Link>
          <div className="">
          <h1 className="text-xl md:text-2xl font-bold mb-4  md:text-left">Set New Password</h1>
               <p className="text-gray-600 mb-8  md:text-left">
                 Secure your account with new a password
               </p>
          </div>
           <form className=" space-y-1 w-[70%] " onSubmit={handleSubmit}>
            <div>
              <label className="block text-gray-700 mb-3 "> Password</label>
                <input
                   type="password"
                   placeholder=".................."
                  className="w-full p-3 rounded mb-4 bg-zinc-300 h-10"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                 />
                  <label className="block text-gray-700 mb-3"> Confirm Password</label>
                   
                <input
                   type="password"
                   placeholder=".................."
                  className="w-full p-3  rounded mb-4 bg-zinc-300 h-10"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                 />

                {error && <p className="text-red-500">{error}</p>}
                  <button className="w-full bg-[#0d47a1] text-white py-2 rounded  h-10" type="submit">
                Reset Password
             </button>
            </div>
           
            
          </form>
          <p className="mt-4 text-center md:text-left">
                Back to Login?{' '}
                 <Link to="/login" className="text-[#0d47a1]">
                   Log in
                 </Link>
               </p>
        </div>
      </section>
  
      
      <section className="hidden md:flex flex-col justify-center items-center bg-[#0d47a1] p-10  ">
        <img src={rightIcon} alt="Right Icon" className="" />
        <img src={lock} alt="Lock Icon" className="" />
          
           <div className="text-center text-white relative mb-[115%] py-3 " > 
           
            <div className="">
            <p className="text-sm font-semibold mb-4 pr-48">Trouble accessing your account?</p>
               <p className="text-sm mb-6">
             Don't worry! We'll help you get back on track. 
            Simply enter your registered email, and we'll send you a link to reset your password.
           </p>
            </div>
            
            <div className="flex justify-around gap-2 mt-5">
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Quick and Secure" />
                 <p className="text-sm">Quick and Secure</p>
               </div>
  
               <div className="flex items-center gap-2">
                <img src={vector} className="w-4" alt="Data Safety" />
                 <p className="text-sm">Data Safety</p>
               </div>
  
              <div className="flex items-center gap-2">
                 <img src={vector} className="w-4" alt="24/7 Support" />
                 <p className="text-sm">24/7 Support</p>
               </div>
  
            </div>
        </div> 
      </section>
  
    </div>
  </main>
      );
    };
    


export default ResetPassword