import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GrHomeRounded } from "react-icons/gr";
import { RiSettingsLine, RiWallet3Line } from "react-icons/ri";
import { MdLogout, MdOutlineStoreMallDirectory } from "react-icons/md";
import { ImHistory } from "react-icons/im";
import { BsPeople } from "react-icons/bs";
import { FaRegIdCard } from "react-icons/fa6";
import { IoIosNotificationsOutline } from "react-icons/io";
import { display } from '../Dashboard/DashBoard';
import { Link } from 'react-router-dom';
import { BiTransferAlt } from "react-icons/bi";
import { GiWallet } from "react-icons/gi";
import { FaRegFileArchive } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import PinDropdown from '../../Reuseables/Dropdown/PinDropdown';
import Logout from '../Logout/Logout';

export function SideNav({ onClose }) {
    const [selectedDashboard, setSelectedDashboard] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedChoice, setSelectedChoice] = useState('');
    const [isEscrowOpen, setIsEscrowOpen] = useState(false);

    const handleChoice = (choice) => {
        setSelectedChoice(choice);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        setSelectedChoice('');
    };

    const showDashboard = (id, name) => {
        setSelectedDashboard(id);
        display(name);
        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const toggleEscrow = () => {
        setIsEscrowOpen(!isEscrowOpen);
    };

    const getDashboardClasses = (dashboardId, additionalClasses = '') => {
        return `flex gap-3 justify-start items-center w-full cursor-pointer 
        ${selectedDashboard === dashboardId ? "bg-blue-50 border-r-4 border-blue-500" : ""} ${additionalClasses}`;
    };

    const getComponentClasses = (dashboardId) => {
        return `font-'lato' ${selectedDashboard === dashboardId ? "text-black" : 'text-gray-500'}`;
    };

    return (
        <div className='flex flex-col py-12 p-4 space-y-4 w-full'>
            <div id='dashboard-assets'
                className={getDashboardClasses("dashboard-assets")}
                onClick={() => showDashboard("dashboard-assets", "assets")}>
                <GrHomeRounded className={getComponentClasses("dashboard-assets")} />
                <p className={getComponentClasses("dashboard-assets")}>Dashboard</p>
            </div>

            <div id='dashboard-kyc'
                className={getDashboardClasses("dashboard-kyc")}
                onClick={() => showDashboard("dashboard-kyc", "kyc")}>
                <FaRegIdCard className={getComponentClasses("dashboard-kyc")} />
                <p className={getComponentClasses("dashboard-kyc")}>Account Verification</p>
            </div>

            <div id='dashboard-wallet'
                className={getDashboardClasses("dashboard-wallet")}
                onClick={() => showDashboard("dashboard-wallet", 'wallet')}>
                <RiWallet3Line className={getComponentClasses("dashboard-wallet")} />
                <p className={getComponentClasses("dashboard-wallet")}>Wallet</p>
            </div>

            <div id='dashboard-escrow'>
                <div
                    className={getDashboardClasses("dashboard-escrow")}
                    onClick={toggleEscrow}
                >
                    <FaRegFileArchive className={getComponentClasses("dashboard-escrow")} />
                    <p className={getComponentClasses("dashboard-escrow")}>Escrow Service</p>
                </div>
                {isEscrowOpen && (
                    <div
                        id='dashboard-pay'
                        className={`${getDashboardClasses("dashboard-pay")} pl-8`}
                        onClick={() => showDashboard("dashboard-pay", 'pay')}
                    >
                        <RiSecurePaymentLine className={getComponentClasses("dashboard-pay")} />
                        <p className={getComponentClasses("dashboard-pay")}>Pay vendor</p>
                    </div>


                )}
                {isEscrowOpen && (
                    <div
                        id='dashboard-cashout'
                        className={`${getDashboardClasses("dashboard-cashout")} pl-8`}
                        onClick={() => showDashboard("dashboard-cashout", 'cashout')}
                    >
                        <GiWallet className={getComponentClasses("dashboard-cashout")} />
                        <p className={getComponentClasses("dashboard-cashout")}>Cashout</p>
                    </div>


                )}
                {isEscrowOpen && (
                    <div
                        id='dashboard-reverse'
                        className={`${getDashboardClasses("dashboard-reverse")} pl-8`}
                        onClick={() => showDashboard("dashboard-reverse", 'reverse')}
                    >
                        <BiTransferAlt className={getComponentClasses("dashboard-reverse")} />
                        <p className={getComponentClasses("dashboard-reverse")}>Reverse Payment</p>
                    </div>


                )}

            </div>

            <div id='dashboard-store'
                className={getDashboardClasses("dashboard-store")}
                onClick={() => showDashboard("dashboard-store", 'store')}>
                <MdOutlineStoreMallDirectory className={getComponentClasses("dashboard-store")} />
                <p className={getComponentClasses("dashboard-store")}>Store</p>
            </div>

            <div id='dashboard-history'
                className={getDashboardClasses("dashboard-history")}
                onClick={() => showDashboard("dashboard-history", 'history')}>
                <ImHistory className={getComponentClasses("dashboard-history")} />
                <p className={getComponentClasses("dashboard-history")}>History</p>
            </div>

            <div id='dashboard-notifications'
                className={getDashboardClasses("dashboard-notifications")}
                onClick={() => showDashboard("dashboard-notifications", 'notifications')}>
                <IoIosNotificationsOutline className={getComponentClasses("dashboard-notifications")} />
                <p className={getComponentClasses("dashboard-notifications")}>Notifications</p>
            </div>

            <div id='dashboard-settings'
                className={getDashboardClasses("dashboard-settings")}
                onClick={() => showDashboard("dashboard-settings", "settings")}>
                <RiSettingsLine className={getComponentClasses("dashboard-settings")} />
                <p className={getComponentClasses("dashboard-settings")}>Settings</p>
            </div>

            <div id='dashboard-support'
                className={getDashboardClasses("dashboard-support")}
                onClick={() => showDashboard("dashboard-support", "support")}>
                <BsPeople className={getComponentClasses("dashboard-support")} />
                <p className={getComponentClasses("dashboard-support")}>Support</p>
            </div>

            <div className='mr-0 mt-3 lg:hidden lg:md:block'>
                <PinDropdown />
            </div>

            <div
                id="logout"
                onClick={() => { handleChoice("") }}
                className="flex gap-3 items-center cursor-pointer w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
            >
                <MdLogout />
                Logout
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-xl font-bold mb-4">Continue to Log Out?</h2>
                        <p className="mb-4">{selectedChoice}</p>
                        <div className="flex flex-row justify-evenly gap-4">
                            <Link to={'/logout'}>
                                <button
                                    className="bg-blue-500 text-white items-start px-4 py-2 rounded hover:bg-blue-700"
                                >
                                    Yes
                                </button>
                            </Link>
                            <button
                                onClick={closePopup}
                                className="bg-red-500 text-white items-end justify-end gap-8 px-4 py-2 rounded hover:bg-red-700"
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

SideNav.propTypes = {
    onClose: PropTypes.func.isRequired,
};
