import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PinDropdown = ({ wallet, escrow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const createPin = () => {
    navigate('/pin');
  };

  const accountDetails = () => {
    navigate('/transfer-details');
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className="relative inline-block w-full text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <button
          type="button"
          className="inline-flex w-full rounded-md border border-blue-200 shadow-sm px-4 py-2 bg-blue-100 text-sm font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
        >
          Security Settings
          <svg
            className="-mr-5 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <a
              onClick={createPin}
              className="block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
              role="menuitem"
            >
              Create pin
            </a>
            <a
              onClick={createPin}
              className="block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
              role="menuitem"
            >
              Update Pin
            </a>
            <a
              onClick={createPin}
              className="block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
              role="menuitem"
            >
              Update Delivery Logistics
            </a>
            <form method="POST" action="#">
              <button
                onClick={accountDetails}
                type="submit"
                className="w-full text-left block px-4 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                role="menuitem"
              >
                Reverse Payment
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default PinDropdown;
