import React, { useState } from 'react'
import { useUser } from '../../userContext/UserContext'
import { useNavigate } from 'react-router-dom';

function Logout() {
  const { logout } = useUser();
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const handleChoice = (choice) => {
    // setSelectedChoice(choice);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // setSelectedChoice('');
  };
  const handleLogout = () => {
    logout(null);
    localStorage.removeItem('authToken');
    console.log('Logout here')

    navigate('/');
  }
  return (
    <>
      {isPopupOpen && (
        <button onClick={handleLogout} className="bg-blue-500 text-white justify-center items-start px-4 py-2 rounded hover:bg-blue-700">Logout</button>
        
      )}
    </>
  );
};

export default Logout;