import {LeftPane} from "../LeftPane/LeftPane";
import {RightPane} from "../RightPane/RightPane";
import {Assets} from "../Assets/Assets";
import {Store} from "../Store/Store";
import {useEffect, useRef, useState} from "react";
import { Wallet } from "../WalletBalance/Wallet";
import {History} from "../History/History";
import React from 'react';
import { Notifications } from "../Notifications/Notifications";
import NINWithFace from "../../KYC/NINWithFace/VerifyWithFace";
import Verification from "../../KYC/NINWithFace/Verification";
import Settings from "../../Components/Settings/Settings";


export let display;

export function DashBoard() {


    const [showAssets, setShowAssets] = useState(false);
    const [showKyc, setShowKyc] = useState(false);
    const [showStore, setShowStore] = useState(false);
    const [showWallet, setShowWallet] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showReverse, setShowReverse] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    // const [showUpdateDeliveryLogistics, setShowUpdateDeliveryLogistics] = useState(false);

    display = (pane) => {
        clear();

        switch (pane) {
            case 'assets':
                setShowAssets(true);
                break;
            case 'kyc':
                setShowKyc(true);
                break;
            case 'store':
                setShowStore(true);
                break;
            case 'wallet':
                setShowWallet(true);
                break;
            case 'reverse':
                setShowReverse(true);
                break;
            case 'history':
                setShowHistory(true);
                break;
            case 'settings':
                setShowSettings(true);
                break;
            case 'notifications':
                setShowNotifications(true);
                break;
            // case 'updateDeliveryLogistics':
            //     setShowUpdateDeliveryLogistics(true);
            //     break;
            default:
                setShowAssets(true);
                break;
           
        }
    }


    const clear = () => {
        setShowAssets(false);
        setShowKyc(false);
        setShowStore(false);
        setShowWallet(false);
        setShowReverse(false);
        setShowHistory(false);
        setShowSettings(false);
        setShowNotifications(false);
        // setShowUpdateDeliveryLogistics(false);       

    }

    const dashboardRef = useRef({ display });
    useEffect(() => {
        dashboardRef.current.display('assets');
    }, []);

    return (
        <main className="">
            <div className="block md:flex w-full justify-center gap-4">
                <div className="flex px-8 w-96">
                    <LeftPane />
                </div>

            <div className="flex px-8">
                { 
                    showAssets && <Assets/> 
                }
                {
                    showKyc && <Verification/>
                }
                {
                    showStore && <Store/>
                }
                {
                    showWallet && <Wallet/>
                }
                {
                   showSettings && <Settings/>
                }
                {
                    showReverse && <Wallet/>
                }
                {
                    showHistory && <History/>
                }
                {
                    showNotifications && <Notifications />
                }
                {/* {
                    showUpdateDeliveryLogistics && <UpdateDeliveryLogistics />
                } */}
            </div>

                <div className="flex flex-col">
                    <RightPane />
                </div>
            </div>
        </main>
    )
}



