import React from "react";
import style from "./SignupSuccess.module.css";

const SignupSuccess = ({ message, onClose }) => {
    return (
        <div className={style.modal}>
            <div className={style.modalContent}>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SignupSuccess;
