import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdShoppingBasket } from "react-icons/md";
import { CiDeliveryTruck } from "react-icons/ci";

import { MdOutlineStackedBarChart } from "react-icons/md";
import logo from "../../../images/Tranxactrust tt.png";

function SideBusinesses() {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const navigate = useNavigate();

  const investrust = () => {
    navigate('/investrust');
    setIsOpen(false);
  };

  const delivery = () => {
    navigate('/logistics-partners');
    setIsOpen(false);
  };

  const trustmonie = () => {
    navigate('/trustmonie');
    setIsOpen(false);
  };

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, 200);
    setHoverTimeout(timeout);
  };

  return (
    <div
      className="container  w-full text-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* <div>
        <p
          type="text"
          className="inline-flex w-fit px-4 cursor-pointer py-2 text-[16px] font-medium text-gray-700 hover:bg-blue-100 focus:outline-none"
        >
          Businesses
        </p>
      </div> */}

      {/* {isOpen && ( */}
        <div
        className="flex flex-col m-0 mt-2 rounded-md shadow-lg bg-blue-50 ring-1 ring-black ring-opacity-5"
        onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="py-1 w-fit" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <p className='text-gray-400 font-bold ml-4'>Businesses</p>
            <div className='flex w-fit'>
              <div className="ml-4 mt-4">
                <img src={logo} alt='Tranxactrust logo' className='w-[30px] h-[20px] cursor-pointer' onClick={trustmonie} />
              </div>
              <div onClick={trustmonie} className='hover:text-blue-500'>
                <a
                  className="flex px-4 mt-2 cursor-pointer ml-1 py-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                  role="menuitem"
                >
                  TRUSTMONIE
                </a>
                <p className="flex px-4 mt-0 py-2 font-bold text-sm text-gray-400 ">
                  Easily activate TRUST on your brand and turn strangers to regular customers
                </p>
              </div>
            </div>

            <div className="justify-evenly flex ml-4 cursor-pointer" onClick={investrust}>
              <MdOutlineStackedBarChart className='text-[#0887ef] w-[35px] h-[35px]' />
              <div>
                <a
                  onClick={investrust}
                  className="flex px-4 py-2 cursor-pointer mr-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                  role="menuitem"
                >
                  INVESTRUST
                </a>
                <p className="flex px-4 mt-0 py-2 font-bold text-sm text-gray-400 ">
                  Easily activate TRUST on your brand and turn strangers to regular customers
                </p>
              </div>
            </div>
            </div>
            
            <div className='gap-8 w-[350px] mt-2 py-0 '>
            <div className="justify-evenly flex ml-4 cursor-pointer" onClick={investrust}>
              <MdShoppingBasket className='text-[#0887ef] w-[40px] h-[40px]' />
              <div>
                <a
                  onClick={investrust}
                  className="flex px-4 py-2 cursor-pointer mr-2 font-bold text-sm text-gray-700 hover:bg-blue-100"
                  role="menuitem"
                >
                  SHOP
                </a>
                <p className="flex px-4 mt-0 py-2 font-bold text-sm text-gray-400 ">
                  Open free online store and stay ever closer to globally paying shoppers
                  on our marketplace.
                </p>
              </div>
            </div>
            <div className="justify-evenly flex ml-2 cursor-pointer" onClick={investrust}>
              <CiDeliveryTruck className='text-[#0887ef] w-[35px] h-[35px]' />
              <div>
                <a
                  onClick={investrust}
                  className="flex px-4 py-2 cursor-pointer font-bold text-sm text-gray-700 hover:bg-blue-100"
                  role="menuitem"
                >
                  SYNCED PAYMENT & DELIVERY
                </a>
                <p className="flex px-4 mt-0 py-2 font-bold text-sm text-gray-400 ">
                  Sell, deliver and cashout same day through our partners.
                </p>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </div>
  );
}

export default SideBusinesses;
