import React, { useState } from 'react'
import { Profile } from './Profile';
import { RiArrowRightSLine } from "react-icons/ri";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Wallets } from '../Wallets/Wallets';
import PinDropdown from '../../Reuseables/Dropdown/PinDropdown';
import { useNavigate } from 'react-router-dom';
import SecuritySettings from './SecuritySettings';

function Settings() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleProfile = () => {
        setShowProfile(!showProfile);
    };

    return (
        <div>
            <div className='flex justify-items-start'>
                <p className='flex text-gray-400 text-sm not-italic font-normal p-12 items-center '>Settings<RiArrowRightSLine /> Home</p>
            </div>

            <div className='flex justify-between items-center flex-row right-12 '>
                <h2 className='text-gray-800 text-3xl font-bold leading-10'>Settings</h2>
                <p className='flex text-blue-800 text-base font-bold leading-6 items-center '>More Assets <IoIosArrowRoundForward /></p>
            </div>
            <div className='hidden lg:md:block mt-12'>
                <Wallets />
            </div>
            <div className='mt-12'>
                <button 
                    onClick={() => navigate('/settings/security')} 
                    className='text-blue-600 px-4 py-2 rounded mb-4'
                >
                    Security
                </button>
            </div>

            <div className='lg:hidden mt-12'>
                <button 
                    onClick={() => navigate('/settings/profile')} 
                    className='text-blue-600 px-4 py-2 rounded mb-4'
                >
                    Profile
                </button>
            </div>

        </div>
    );
};

export default Settings;

