import React, { useState, useEffect, useRef,useMemo  } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineMail } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import style from "./Signup.module.css";
import { useUser } from "../../userContext/UserContext";
import globe from "../../images/jam_world.svg";
import tranxact from "../../images/Tranxact on.png";
import Spinner from "./Spinner/Spinner";
import eye from "../../images/ph_eye.svg";
import SignupSuccess from "./SignupSuccess";
import userIcon from "../../images/Group 13.png"
import rightIcon from '../../images/Ellipse 4.png';
import vector from '../../images/Vector.png';
import close from "../../images/eye-close-svgrepo-com.svg";
import userplus from "../../images/user-plus.png"
import Select from 'react-select';
import countryList from 'react-select-country-list';
const Signup = () => {
    const options = useMemo(() => countryList().getData(), []);

    const [role, setRole] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [otp1, setOtp1] = useState('');
    const [otp2, setOtp2] = useState('');
    const [otp3, setOtp3] = useState('');
    const [otp4, setOtp4] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpTimer, setOtpTimer] = useState(180);
    const [registrationMessage, setRegistrationMessage] = useState('');
    const [verificationMessage, setVerificationMessage] = useState('');
    const navigate = useNavigate();
    const { login } = useUser();

    const [userData, setUserData] = useState({
        phoneNumber: '',
        nationality: '',
        businessName: '',
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: ''
    });
    const [value, setValue] = useState()
    const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    
    const handleCountryChange = (selectedOption) => {
      setUserData({ ...userData, nationality: selectedOption.value });
    };
  
    useEffect(() => {
        let timer;
        if (isOtpSent && otpTimer > 0) {
            timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [isOtpSent, otpTimer]);

      const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handlePhoneChange = (value) => {
        setPhoneNumber(value);
        setTimeout(() => setValid(validatePhoneNumber(value)), 300);
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\d{10,13}$/;
        return phoneNumberPattern.test(phoneNumber);
    };

    const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

    const createUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        try {
            const userPhoneData = {
                ...userData,
                phoneNumber: phoneNumber,
                password: password,
                role: role
            };

            const response = await fetch(`${baseUrl}/api/v2/register`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userPhoneData),
                credentials: 'include'
            });

            if (response.ok) {
                const user = await response.json();
                console.log('User created successfully:', user);
                setRegistrationMessage('User created successfully. Please check your email for the OTP.');
                login(user);
                setIsOtpSent(true);
                setOtpTimer(180);
                sendOtp(user.email);
            } else {
                const data = await response.json();
                console.error('Error response from server:', data);
                setError(data.message || 'An error occurred during sign up');
            }
        } catch (error) {
            console.error('An error occurred during sign up: ', error.message);
            setError('An error occurred during sign up');
        } finally {
            setIsLoading(false);
        }
    };

    const sendOtp = async (email, isResend = false) => {
        const endpoint = isResend ? '/api/v2/resend/otp' : '/api/v2/validate/otp';
        try {
            console.log("User email >>" ,userData.email)
            const response = await fetch(`${baseUrl}${endpoint}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ credential: userData.email }),
                credentials: 'include'
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.message || 'An error occurred while sending OTP');
            }
        } catch (error) {
            console.error('OTP not sent:', error.message);
            setError('An error occurred while sending OTP');
        }
    };

    const verifyOtp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);
        const otp = `${otp1}${otp2}${otp3}${otp4}`;
        try {
            console.log(userData.username, otp);
            const response = await fetch(`${baseUrl}/api/v2/validate/otp`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: userData.username, otp }),
                credentials: 'include'
            });

            const contentType = response.headers.get("content-type")
            let data;
            if (contentType && contentType.indexOf("application/json") !== -1) {
                data = await response.json();
            } else {
                data = await response.text();
            }

            if (response.ok) {
                setVerificationMessage('OTP verification successful. Redirecting to dashboard...');
                setShowModal(false);
                navigate('/dashboard');
            } else {
                setVerificationMessage(data.message || data || 'OTP verification failed');
                setError(data.message || data || 'OTP verification failed');
                console.error('OTP verification failed with response:', data);
            }
        } catch (error) {
            console.error('OTP verification failed:', error.message);
            setError('An error occurred during OTP verification');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            if (index === 0) setOtp1(value);
            if (index === 1) setOtp2(value);
            if (index === 2) setOtp3(value);
            if (index === 3) setOtp4(value);
            if (value && index < 3) otpRefs[index + 1].current.focus();
        }
    };

    const handleOtpKeyDown = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            otpRefs[index - 1].current.focus();
        }
    };

    const resendOtp = () => {
        sendOtp(userData.email, true);
        setOtpTimer(180);
    };

    const closeModal = () => {
        setShowModal(false);
        navigate('/dashboard');
    };

    return (
        <main className="flex-1 bg-cover bg-center pt-[58px] pb-[58px]  ">
        
        {showModal && (
          <SignupSuccess
            message="Signup successful! Please check your email and verify it."
            onClose={closeModal}
          />
        )}
        {isOtpSent ? (
          <form onSubmit={verifyOtp}>
            <div className={style.otpBox}>
              <p>Enter the OTP sent to your email</p>
              <div className={style.otpInputs}>
                {Array(4)
                  .fill()
                  .map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      ref={otpRefs[index]}
                      value={[otp1, otp2, otp3, otp4][index]}
                      onChange={(e) => handleOtpChange(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      className={style.otpInput}
                      required
                    />
                  ))}
              </div>
              <button
                type="submit"
                disabled={isLoading}
                className="justify-center font-semibold bg-blue-500 text-white p-4 rounded-lg"
              >
                {isLoading ? <Spinner /> : 'Verify OTP'}
              </button>
              {otpTimer > 0 ? (
                <p>OTP expires in {otpTimer} seconds</p>
              ) : (
                <button
                  type="button"
                  onClick={resendOtp}
                  className="justify-center font-semibold bg-green-500 text-white p-4 rounded-lg"
                >
                  Resend OTP
                </button>
              )}
              {error && <p className={style.error}>{error}</p>}
              {verificationMessage && <p className={style.success}>{verificationMessage}</p>}
            </div>
          </form>
        ) : (
          <>
            <div className="flex flex-col md:flex-row h-auto md:h-auto w-full max-w-[80rem] mx-auto px-4 sm:px-8 md:px-32">
              <section className="flex flex-col justify-center items-center bg-white p-10 shadow-lg rounded">
                <div className="">
                  <Link to={'/'}>
                    <img className="h-10 md:h-20 mb-4" src={tranxact} alt="Tranxact Logo" />
                  </Link>
                  <div className="">
                    <h1 className="text-xl font-semibold mb-5">Create your account</h1>
                    <p className="text-gray-600 md:mb-2">Join Tranxactrust and start securing your transactions today.</p>
                  </div>
                  <form className="space-y-1 w-[71%]" onSubmit={createUser}>
                    
                    <div className="flex gap-4">
                      <div className="">
                        <label className="block text-gray-700 mb-3">First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="w-[99%] p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                          required
                        value={userData.firstName || ''}
                         onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 mb-3">Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="w-[99%] p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                          value={userData.lastName || ''}
                          onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-3">Username</label>
                      <input
                        type="text"
                        placeholder="Username"
                        className="w-full p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                        required
                        value={userData.username || ''}
                         onChange={(e) => setUserData({ ...userData, username: e.target.value })}
                      />
                    </div>
                    <div>
                    <div className="flex justify-between">
                    <label className="block text-gray-700 mb-3">Country</label>
                    <img className=" size-10 h-7" src={globe} alt="globe" />
                    </div>
                  
                      <Select
                      options={options}
                      value={options.find((option) => option.value === userData.nationality)}
                            onChange={handleCountryChange}
                               placeholder="Select a country..."
                                   className="w-full mb-4"  />
                    </div>
                   
                    <div>
                      <label className="block text-gray-700 mb-3">Legal Business Name</label>
                      <input
                        type="text"
                        placeholder="Legal Business Name"
                        className="w-full p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                        required
                        value={userData.businessName}
                        onChange={(e) => setUserData({ ...userData, businessName: e.target.value })}
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 mb-3">Email</label>
                      <input
                        type="email"
                        placeholder="johnDoe@email.com"
                        className="w-full p-3 border border-gray-300 rounded mb-4 bg-zinc-300 h-10"
                        required
                         value={userData.email || ''}
                          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      />
                    </div>
                    <div className="">
                    <label className="block text-gray-700 mb-3">phone Number</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                       country={'ng'}
                       className=" overflow-y-auto"
                       inputProps={{ required: true }}
                       value={phoneNumber}
                       onChange={handlePhoneChange}
                       />
                    {!valid && <p >Please enter a valid phone number.</p>} 
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-gray-700 mb-3">Password</label>
                       <div className="relative">
                               <input
                          className="w-full pr-10 p-2 border border-gray-300 rounded-md"
                          type={passwordVisible ? 'text' : 'password'}
                        value={password}
                    onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
    />
        <img
      src={passwordVisible ? close : eye}
      alt="Toggle Password Visibility"
      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-10"
      onClick={togglePasswordVisibility}
    />
  </div>
</div>

                    <p className>Are you a software developer?</p>
                    <div className="flex  gap-6">
                    
                           <div>
                                      <input
                                         type="radio"
                                         id="developer"
                                         name="role"
                                         value="DEVELOPER"
                                        checked={role === 'DEVELOPER'}
                                        onChange={(e) => setRole(e.target.value)}
                                     />
                                     <label htmlFor="developer">Yes</label>
                                      </div>
                                      
                                      <div>
                                     <input
                                         type="radio"
                                         id="business"
                                         name="role"
                                         value="BUSINESS"
                                         checked={role === 'BUSINESS'}
                                        onChange={(e) => setRole(e.target.value)}
                                    />
                                    <label htmlFor="business">No</label>
                                 </div>
                               </div>
                     
                            <div className>
                             {error && <p className>{error}</p>}

                             <button className="w-full flex justify-center items-center p-3 py-2 mt-3 text-white border border-gray-300 rounded mb-4  bg-blue-600 h-10" type="submit" disabled={isLoading}>
                                 {isLoading ? <Spinner /> : 'Create my account'}
                            </button>
                         </div>
                         
                         <p className="">By clicking the
                                        “Create your account” button, you accept Tranxactrust's
                             <Link to={'/'} className="" > <span className=" text-blue-500">terms of acceptable use and Data Processing Agreement.</span> 
                                </Link></p>
                               <p className="">To learn more about how Tranxactrust collects,
                               uses and discloses your personal data, please read our
                             <Link to={'/'} className="text-blue-500" > Privacy Policy.</Link></p>
                  </form>
                 
                  <div className="flex items-center gap-3 mt-3">
                      <p>Already have an account?</p>
                                 <Link to={'/login'}>
                            <button className="w-24 py-1 h-8 bg-blue-600 text-white rounded-lg">Login</button>
                              </Link>
                             </div>
                </div>
              </section>
      
              <section className="hidden md:flex flex-col justify-center items-center bg-[#0d47a1] p-10 rounded">
                <img src={rightIcon} alt="Right Icon" className="" />
                <div className="relative">
                 <img src={userIcon} alt="User Icon" className="w-70 h-40" />
             
                <img
                  src={userplus}
                alt="User Plus Icon"
             className="absolute top-7 right-32 w-20 h-20"
                />
                </div>
               
                <div className="text-center text-white relative mb-[115%] py-3">
                  <p className="text-sm font-semibold mb-4 pr-64">Welcome to Tranxactrust</p>
                  <p className=" leading-relaxed ">
                     Start your journey towards secure, transparent transactions 
                     with our trusted escrow service. Manage your deals with 
                     confidence and ease.
                  </p>
      
                  <div className="flex justify-around gap-2 mt-5">
                    <div className="flex items-center gap-2">
                      <img src={vector} className="w-4" alt="Quick and Secure" />
                      <p className="text-sm">Quick and Secure</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={vector} className="w-4" alt="Real-Time Updates" />
                      <p className="text-sm">Real-Time Updates</p>
                    </div>
                    <div className="flex items-center gap-2">
                      <img src={vector} className="w-4" alt="24/7 Support" />
                      <p className="text-sm">24/7 Support</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </main>



       
    );
};

export default Signup;
