import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cac from "../../assets/download.jpeg";

function VerifyCAC() {
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('Unverified');

    const verifyBVN = () => {
        setVerificationStatus('Pending');
        setTimeout(() => {
            const isSuccess = true; 

            if (isSuccess) {
                setVerificationStatus('Verified');
            } else {
                setVerificationStatus('Failed');
            }
        }, 2000); 

        navigate('/bvn-verification');
    }
    // bg-gray-200
    return (
        <div className="flex flex-col gap-2 item-start bg-indigo-100 bg-gradient-to-r from-green-200 to-green-600 lg:w-[80%] w-full rounded-lg">
                        {/* <img src={nin} alt='nin' className='lg:w-[50px] lg:h-[50px] lg:ml-20 lg:my-12 w-[40px] h-[40px] my-2 ml-12' /> */}

            <div className="flex flex-col gap-4 items-center justify-between mt-2">
                <div className="flex gap-10 items-center">
                    <p className="text-green-700 text-3xl font-bold leading-10">CAC</p>
                </div>
                <p className={`w-fit h-[30px] font-medium rounded-[5px] p-1 border-1 border-blue-600 ${verificationStatus === 'Verified' ? 'text-green-600 bg-green-200' : verificationStatus === 'Pending' ? 'text-yellow-600 bg-yellow-200' : verificationStatus === 'Failed' ? 'text-red-600 bg-red-200' : 'text-gray-600 bg-green-300'}`}>
                    {verificationStatus}
                </p>
                <button
                    onClick={verifyBVN}
                    className='flex gap-3 items-center text-xl px-4 py-2 text-green-900 font-medium rounded-[5px]'
                    disabled={verificationStatus === 'Verified' || verificationStatus === 'Pending'}
                >
                    Verify Now           
                </button>
            </div>

            <div>
            </div>
        </div>
    );
}

export default VerifyCAC;
