import React from 'react';

const Popup = ({ nin, setNin, handleNextStep, message, closePopup }) => {
  const handleInputChange = (e) => {
    setNin(e.target.value);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg relative w-3/4 max-w-md">
        <input
          type="text"
          placeholder="Enter your NIN"
          value={nin}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mt-4"
        />
        <button
          onClick={handleNextStep}
          className="flex gap-3 items-center justify-center m-auto px-4 mt-4 py-2 text-blue-600 bg-blue-200 font-medium rounded-lg"
        >
          Next
        </button>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
        <button
          onClick={closePopup} 
          className="absolute top-4 right-4 text-2xl cursor-pointer"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default Popup;
