import React from 'react';
import { Wallets } from '../../Components/Wallets/Wallets';
import { Payment } from '../../Components/Payment/Payment';
import { RiArrowRightSLine } from "react-icons/ri";
import { IoIosArrowRoundForward } from "react-icons/io";
import VerifyNIN from './VerifyNIN';
import VerifyBVN from './VerifyBVN';
import VerifyCAC from './VerifyCAC';
import VerifyAddress from './VerifyAddress';
import Footer from '../../Components/Footer/Footer';

function Verification() {
  return (
    <div className='w-[900px] mb-8'>
      <div className='flex justify-items-start'>
        <p className='flex text-gray-400 text-sm not-italic font-normal p-12 items-center '>kyc<RiArrowRightSLine /> Home</p>
      </div>

      <div className='flex justify-between items-center flex-row right-12 '>
        <h2 className='text-gray-800 text-3xl font-bold leading-10'>Account Verification</h2>
        <p className='flex text-blue-800 text-base font-bold leading-6 items-center '>More Assets <IoIosArrowRoundForward/></p>
      </div>
      {/* <div className='mt-12'>
        <Wallets />
      </div> */}

      {/* <div className='flex lg:flex-row flex-col gap-6 lg:py-20 py-5 justify-center items-center'> */}
      <div className='lg:grid lg:grid-cols-2 grid grid-cols-1 gap-2 lg:py-10 py-7 '>
        <VerifyNIN/>
        <VerifyBVN /> 
        {/* <VerifyAddress />
        <VerifyCAC/> */}
      </div>
      
      {/* <div className='flex lg:flex-row flex-col gap-6 py-0 justify-center items-center'> */}
      <div className='lg:grid lg:grid-cols-2 grid grid-cols-1 gap-6 lg:py-10 py-7 '>
      <VerifyAddress />
        <VerifyCAC/>
      </div>
    </div>
  )
}

export default Verification